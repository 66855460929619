import { useWeb3React } from '@web3-react/core';
import { AuthRepository } from '../../connectors/repositories/auth';
import { setCookie } from 'nookies';
import { useState } from 'react';

export const useAuth = () => {
  const [isComplete, setIsComplete] = useState(false);
  const { account, provider } = useWeb3React();

  const authAccount = async () => {
    const { result: nonce } = await AuthRepository.getNonce();

    if (nonce) {
      try {
        const resultMessage = await provider?.getSigner(account).signMessage(nonce);
        const { result: apiToken } = await AuthRepository.login(account, resultMessage, nonce);

        setCookie(null, 'apiToken', apiToken, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
        setIsComplete(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return {
    isComplete,
    authAccount,
  };
};
