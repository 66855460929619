/* eslint-disable react/react-in-jsx-scope */
import { callNotification } from 'helpers/notification';
import copyToClipBoard from 'copy-to-clipboard';

export const copy = (value) => {
  const isCopied = copyToClipBoard(value);

  if (isCopied) {
    callNotification({
      type: 'success',
      message: 'Copied',
    });
  } else {
    callNotification({ type: 'error', message: 'Copy error' });
  }
};
