import React from 'react';

export const Title = ({ className, title, desc }) => {
  return (
    <div className="flex flex-col items-center space-y-[4rem] lg:space-y-[2rem]">
      <span
        className={`${className} text-center tracking-[-2px] font-600 space-grotesk max-w-[90rem] lg:text-[3.2rem] lg:leading-[3.52rem]`}
      >
        {title}
      </span>
      <span className="text-[1.8rem] font-400 leading-[2.52rem] text-white text-center opacity-50  max-w-[79.4rem] lg:text-[1.6rem] lg:leading-[2.24rem]">
        {desc}
      </span>
    </div>
  );
};
