import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import ArrowDown from '../../../../../assets/icons/main/arrow-down.svg';
import SettingWhiteIcon from '../../../../../assets/icons/main/settingWhite.svg';
import SettingPurpleIcon from '../../../../../assets/icons/main/settingPurple.svg';
import PenIcon from '../../../../../assets/icons/main/pen.svg';
import { useOpenSale } from '../../../../../helpers/hooks/useOpenSale';
import config from '../../../../../helpers/config';
import { useCallTransaction } from '../../../../../helpers/hooks/useCallTransaction';
import { useModal } from '../../../../../helpers/hooks/useModal';
import { ActivateModal } from '../../../../../components/Modals/ActivateModal';
import { callNotification } from '../../../../../helpers/notification';

import { useSelector } from 'react-redux';
import { getBalance } from '../../../../../store/walletSlice/selectors';

import { Trans } from 'react-i18next';
import { Button } from '../../../../../components/Landing/Button';

import { useTranslation } from 'react-i18next';
import { DEFAULT_SLIPPAGE_SWAP, BUY_TIMEOUT } from '../../../../../helpers/constants';
import { SecondEmptyTimer } from '../../../../../components/Timer/SecondEmptyTimer';
import { isSameOrBefore } from '../../../../../helpers/date';
import { fromUnixTime } from 'date-fns';

export const Swap = ({ uplineAddress }) => {
  const [valueFrom, setValueFrom] = useState(0);
  const [valueTo, setValueTo] = useState(0);
  const { openedModal, onOpen, onClose } = useModal();
  const { account, chainId } = useWeb3React();
  const { polyValue, isLoading, openSaleBuy, getPolyValue } = useOpenSale();
  const { transactionInfo, onCallTransaction, setTransactionInfo, resetTransactionInfo } = useCallTransaction();

  const { pol: balancePol } = useSelector(getBalance);
  const { t } = useTranslation();

  const isInsufficientBalance = balancePol < valueFrom;

  const currencies = {
    POL: {
      img: '/icons/currency/pol.svg',
      title: 'POL',
    },
    POLLY: {
      img: '/icons/currency/poly.svg',
      title: 'POLLY',
    },
  };

  useEffect(() => {
    if (transactionInfo.isWaiting) {
      callNotification({type: 'info', message: t('transaction.progress')})
    }
    if (transactionInfo.isError) {
      setLockBtn(false);
      callNotification({type: 'error', message: t('transaction.error')})
      resetTransactionInfo();
    }
    if (transactionInfo.isSuccess) {
      setLockBtn(false);
      callNotification({type: 'success', message: `${t('transaction.purchased')} $POLLY`})
      resetTransactionInfo();
    }
  }, [transactionInfo])

  useEffect(() => {
    if (account && chainId === config.allowedChainId && valueFrom > 0) {
      getPolyValue(valueFrom);
    }
  }, [account, valueFrom, chainId]);

  useEffect(() => {
    let interval;
    if (account && chainId === config.allowedChainId && valueFrom > 0 && !document?.hidden) {
      interval = setInterval(() => {
        getPolyValue(valueFrom);
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [account, chainId, valueFrom, document]);

  useEffect(() => {
    setValueTo(polyValue.toFixed(3));
  }, [polyValue]);

  const onBuyClick = async () => {
    setLockBtn(true);
    resetTransactionInfo();

    const result = await openSaleBuy(valueFrom, polyValue, slippageValue, uplineAddress);

    onCallTransaction(result);
    setLockBtn(false);
  };

  const depositValues = [25, 50, 100];

  const setDepositValue = (percent) => {
    const result = (balancePol * (percent / 100)).toFixed(3);
    setValueFrom(result);
  }

  const swapItemFrom = (value = 0, setValue, currency, readOnly = false, type = 'pay') => {

    const handleChange = (event) => {
      {
        let { value, min, max } = event.target;
        value = value === '' ? value : Math.max(Number(min), Math.min(Number(max), Number(value)));
    
        setValue(value);
      };
    }

    return (
      <div className="relative h-[12.7rem] flex items-center justify-between bg-white-50 border border-solid border-white-100 p-[2.4rem] pr-[1.2rem] rounded-[2rem] space-x-[1.4rem]">
        <div className="flex-1 flex flex-col items-start justify-center space-y-[0.8rem]">
          <span className="text-white text-[1.4rem] font-400 opacity-50">
            <Trans i18nKey="landing.openSale.rightBar.youPay">You pay</Trans>
          </span>
          <input
            readOnly={readOnly}
            className={`bg-transparent focus:outline-none w-full focus:text-white text-[2.4rem] font-600 text-white placeholder:text-[1.8rem] placeholder:text-white-300`}
            type="number"
            min="0"
            max="10000000"
            placeholder={t('placeholder.enterPol')}
            value={value}
            onChange={(e) => handleChange(e)}
          />
          {/* <span className="text-white text-[1.4rem] font-400 opacity-50">$value</span> */}
        </div>
        <div className="w-[10rem] flex items-center justify-start p-[0.8rem] bg-white-40 rounded-[1.6rem] space-x-[0.8rem]">
          <img className="h-[2.4rem] w-[2.4rem]" src={currency.img} alt="" />
          <span className="text-white text-[1.6rem] leading-[1.8rem]">{currency.title}</span>
        </div>
        <div className="!ml-0 absolute top-[1rem] right-[1.2rem] flex items-center justify-end space-x-[0.8rem]">
          {depositValues.map((item, itemIndex) => {
            const text = item === 100 ? 'MAX' : `${item}%`;
            return (
              <button onClick={() => setDepositValue(item)} className='custom-transition bg-white-50 hover:bg-white-100 rounded-[1rem] px-[0.8rem] py-[0.4rem] flex items-center justify-center' key={itemIndex}>
                <span className='text-white text-[1.2rem] font-500'>{text}</span>
              </button>
            )
          })}
        </div>
      </div>
    );
  };

  const swapItemTo = (value = 0, setValue, currency, readOnly = false, type = 'pay') => {
    return (
      <div className="h-[12.7rem] flex items-center justify-between bg-white-50 border border-solid border-white-100 p-[2.4rem] pr-[1.2rem] rounded-[2rem] space-x-[1.4rem]">
        <div className="flex-1 flex flex-col items-start justify-center space-y-[0.8rem]">
          <span className="text-white text-[1.4rem] font-400 opacity-50">
            <Trans i18nKey="landing.openSale.rightBar.youReceive">You receive</Trans>
          </span>
          <input
            readOnly={readOnly}
            className={`bg-transparent focus:outline-none w-full focus:text-white text-[2.4rem] font-600 ${
              readOnly ? 'text-white' : 'text-white-500'
            }`}
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {/* <span className="text-white text-[1.4rem] font-400 opacity-50">$value</span> */}
        </div>
        <div className="w-[10rem] flex items-center justify-start p-[0.8rem] bg-white-40 rounded-[1.6rem] space-x-[0.8rem]">
          <img className="h-[2.4rem] w-[2.4rem]" src={currency.img} alt="" />
          <span className="text-white text-[1.6rem] leading-[1.8rem]">{currency.title}</span>
        </div>
      </div>
    );
  };

  const [isShowSlippagePercents, setIsShowSlippagePercents] = useState(false);
  const [slippageValue, setSlippageValue] = useState(DEFAULT_SLIPPAGE_SWAP);

  useEffect(() => {    
    const parseSlippage = localStorage.getItem("polly_slippage")
    if (!!parseSlippage) {
      setSlippageValue(Number(parseSlippage));
    } 
  }, [])

  
  const slippageRender = () => {
    const slVariants = [0.1, 0.5, 1];

    const handleChange = (event) => {
      {
        let { value, min, max } = event.target;
        value = value === '' ? value : Math.max(Number(min), Math.min(Number(max), Number(value)));
    
        setSlippageValue(value);

        localStorage.setItem("polly_slippage", value);
      };
    }

    return (
        <div className="flex flex-col space-y-[1.2rem] w-full">
          <div className="flex items-center justify-between w-full">
            <span className="text-[1.4rem] font-400 text-white-500">Slippage Tolerance</span>
            <div className="flex items-center justify-end space-x-[1rem]">
              <span className="text-[1.4rem] font-400 text-white-500">{slippageValue}%</span>
              <button className='group relative h-[2rem] w-[2rem]' onClick={() => setIsShowSlippagePercents(value => !value)}>
                <img className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 custom-transition duration-300 h-0 w-0 opacity-0 ${isShowSlippagePercents ? 'opacity-100 group-hover:opacity-80 !h-[2rem] !w-[2rem]' : ''}`} src={SettingPurpleIcon} alt="" />
                <img className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 custom-transition duration-300 h-[2rem] w-[2rem] opacity-50 group-hover:opacity-80 ${isShowSlippagePercents ? 'opacity-0 !h-0 !w-0' : ''}`} src={SettingWhiteIcon} alt="" />
              </button> 
            </div>
          </div>
            <div className={`flex items-center justify-end space-x-[0.8rem] opacity-0 ${isShowSlippagePercents ? 'custom-transition duration-500 !opacity-100' : ''}`}>
              {slVariants.map((item, itemIndex) => {
                const active = slippageValue === item;
                return (
                  <button onClick={() => setSlippageValue(item)} className={`custom-transition max-w-[6rem] w-full p-[1rem] rounded-[1rem] bg-white-50 text-white-500  hover:bg-white-100 ${active ? '!bg-[#985AFF] !text-white' : ''}`} key={itemIndex}>
                    <span className='text-[1.4rem] font-400'>{item}%</span>
                  </button>
                )
              })}
              <div className="relative w-fit h-full">
                <input className='max-w-[8.9rem] w-full p-[1rem] pr-[4.8rem] text-[1.4rem] text-white font-500 bg-white-10 rounded-[1rem] border border-solid border-white-100' type="number" min="0" max="100" value={slippageValue} onChange={(e) => handleChange(e)} />
                <div className="absolute top-1/2 -translate-y-1/2 right-[1rem] flex items-center justify-end space-x-[0.6rem]">
                  <span className='text-white-500 text-[1.4rem] font-500'>%</span>
                  <img className='w-[1.4rem] h-[1.4rem]' src={PenIcon} alt="" />
                </div>
              </div>
            </div>
        </div>
    )
  }

  const isSoldoutSwap = Number(valueFrom) !== 0 && Number(valueTo) === 0;

  const [lockBtn, setLockBtn] = useState(false);
  const [intervalTimeBuy, setIntervalTimeBuy] = useState(null);
  const [isComleteIntervalBuy, setIsCompleteIntervalBuy] = useState(false);
  const showTimer = intervalTimeBuy !== null && !isComleteIntervalBuy;

  useEffect(() => {
    const intervalSave = localStorage.getItem('buy_polly_timer');
    if (intervalSave) {
      if (isSameOrBefore(fromUnixTime(intervalSave))) {
        setIsCompleteIntervalBuy(true)
        setIntervalTimeBuy(null)
      } else {
        setIsCompleteIntervalBuy(false)
        setIntervalTimeBuy(intervalSave)
      }
    } else {
      setIsCompleteIntervalBuy(true);
      setIntervalTimeBuy(null)
    }
  }, [])

  const setTimerValue = () => {
    if (!intervalTimeBuy) {
      setIsCompleteIntervalBuy(false);
      setIntervalTimeBuy((Date.now() + BUY_TIMEOUT) / 1000);
      localStorage.setItem('buy_polly_timer', (Date.now() + BUY_TIMEOUT) / 1000);
      setIsCompleteIntervalBuy(false);
    }
  }

  useEffect(() => {
    if (transactionInfo.hash && transactionInfo.isWaiting) {
      setTimerValue();
    }
  }, [transactionInfo])

  const onCompleteTimer = () => {
    setIsCompleteIntervalBuy(true);
    setIntervalTimeBuy(null);
    setLockBtn(false);
  }

  const renderBuyButton = () => {
    if (isLoading || transactionInfo.isWaiting || lockBtn) {
      return <div className="loading-animation__white !lg:w-[2.4rem] !lg:h-[2.4rem]"/>
    }

    if (showTimer) {
      return <SecondEmptyTimer time={intervalTimeBuy} isCompleted={isComleteIntervalBuy} onComplete={() => onCompleteTimer()} />;
    }

    if (!account) {
      return <Trans i18nKey="main.connectWallet">Connect wallet</Trans>
    } else {
      if (valueFrom === 0) {
        return <Trans i18nKey="main.enterAmountInPol">Enter the amount in POL</Trans>
      }
      if (isInsufficientBalance) {
        return <Trans i18nKey="main.insufficientBalance">Insufficient balance</Trans>
      }

      if (isSoldoutSwap) {
        return <Trans i18nKey="main.soldout">Sold out</Trans>
      }

      return <Trans i18nKey="main.buy">Buy</Trans>
    }
  }

  return (
    <div className="flex flex-col space-y-[2.4rem] bg-[#0C0C0E] bg-[linear-gradient(180deg,rgba(255,255,255,0.07)_0%,rgba(255,255,255,0.01)_100%)] p-[4rem] w-[56rem] h-auto rounded-[3rem] lg:p-[2rem] lg:w-full">
      <div className="flex flex-col flex-1 space-y-[2.4rem] h-full w-full">
        <div className="relative flex flex-col space-y-[1.2rem] w-full">
          {swapItemFrom(valueFrom, setValueFrom, currencies.POL)}
          <div className="!mt-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-purple-matrix flex items-center justify-center rounded-full w-[4.8rem] h-[4.8rem] border border-solid border-[rgba(152,90,255,0.10)]">
            <img className="w-[2.4rem] h-[2.4rem]" src={ArrowDown} alt="" />
          </div>
          {swapItemTo(valueTo, setValueTo, currencies.POLLY, true)}
        </div>
        {slippageRender()}
      </div>
      <Button
        type="purple"
        onClick={!account ? () => onOpen() : () => onBuyClick()}
        disabled={isSoldoutSwap || isInsufficientBalance || isLoading || transactionInfo.isWaiting || valueFrom === 0 || lockBtn || showTimer}
        className="mt-auto h-[5.2rem] flex items-center justify-center w-full disabled:opacity-50"
      >
        {renderBuyButton()}
      </Button>
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
