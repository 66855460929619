import { useState } from 'react';
import { setCookie, parseCookies } from 'nookies';

export const useCheckTiersReflink = () => {
  const [uplineData, setUplineData] = useState(null);

  const checkReflink = () => {
    const refData = new URL(window.location.toString()).searchParams.get('ref');

    if (refData) {
      setUplineData({ type: 'ref', value: refData });
      setCookie(null, 'polynetica_tiers_upline', refData, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
    } else {
      const parseUpline = parseCookies()?.['polynetica_tiers_upline'];
      if (!!parseUpline) {
        setUplineData({ type: 'ref', value: parseUpline });
      }
    }
  };

  return {
    uplineData,
    checkReflink,
  };
};
