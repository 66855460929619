import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      id: '',
    },
    balance: {
      pol: 0,
      polly: 0,
    },
    saleRefInfo: {
      totalReferrals: 0,
      totalRewards: 0,
      refBonusToSend: 0,
      currentReferral: null,
      exists: null,
    },
  },
  reducers: {
    updateUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    clearUser(state) {
      state.user = {};
    },
  },
});

export const userReducer = userSlice.reducer;

export const { updateUser, clearUser } = userSlice.actions;
