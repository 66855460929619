import React from 'react';
import { Title } from '../../../components/Landing/Title';
import { Trans } from 'react-i18next';

export const Overview = () => {
  return (
    <div className="flex flex-col space-y-[8rem] mt-[20rem] lg:space-y-[4rem] lg:mt-[7.8rem]">
      <Title
        className="text-white text-[6rem] leading-[6.6rem]"
        title={<Trans i18nKey="landing.overview.title">Polynetica Overview</Trans>}
        desc={<Trans i18nKey="landing.overview.desc">Introducing Polynetica: The Future of Decentralized Finance</Trans>}
      />
      <div className="flex items-center justify-center z-[1] w-full rounded-[3rem] w-full h-[67.5rem] bg-white-70 border-[0.625px] border-solid border-white-100 p-[2rem] lg:p-[1rem] lg:h-[19.7rem] lg:rounded-[2rem]">
        <iframe
          className="rounded-[2rem] h-[63.5rem] lg:h-full lg:rounded-[1rem]"
          width="1160"
          src="https://www.youtube.com/embed/UJVXdQjPpQo?modestbranding=1&controls=0&rel=0&showinfo=0&iv_load_policy=3"
          title="YouTube video player"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
