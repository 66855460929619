import React from 'react';
import { WALLETS } from '../../../connectors/wallets';
import { useTryActivation } from 'helpers/hooks/useTryActivation';
import { Modal } from '../../Modal';
import DisabledIcon from '../../../assets/icons/main/disabled.svg';
import { Trans } from 'react-i18next';

export const ActivateModal = ({ openedModal, handleCloseModal }) => {
  const { tryActivation } = useTryActivation();

  const onWalletClick = (wallet) => () => {
    tryActivation(wallet.connector);
    handleCloseModal();
  };

  const walletItem = (wallet) => {
    return (
      <button
        className={`relative translate-all min-h-[5.6rem] flex items-center justify-between p-[0.8rem] pr-[2.4rem] border border-solid custom-transition border-white-50 bg-white-50 rounded-[1.2rem] hover:bg-purple-matrix-100 hover:border-purple-matrix disabled:opacity-10 disabled:pointer-events-none disabled:cursor-not-allowed`}
        onClick={onWalletClick(wallet)}
        disabled={wallet.disabled}
        key={wallet.title}
      >
        <div className="flex items-center justify-start space-x-[2rem]">
          <div className="relative w-[4rem] h-[4rem]">
            {wallet.disabled && (
              <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" src={DisabledIcon} alt="" />
            )}
            <img className="w-full h-full" alt="walletIcon" src={wallet.icon} />
          </div>
          <span className="text-[1.6rem] font-500 text-white">{wallet.title}</span>
        </div>
        <span className="text-[1.2rem] font-400 text-white-500">{wallet.subtitle}</span>
        {!wallet.disabled && (
          <div className="w-[0.4rem] h-[0.4rem] rounded-full absolute top-[1rem] right-[1rem] bg-[#51F689]"></div>
        )}
      </button>
    );
  };

  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal}>
      <div className="flex flex-col items-center justify-center lg:items-start bg-white-50 backdrop-blur-[5rem] border border-solid border-white-200 min-h-[46.3rem] w-[46rem] rounded-[3rem] p-[4rem] lg:px-[2rem] lg:pb-[8rem] lg:rounded-none lg:rounded-t-[3rem] lg:w-full lg:min-h-[50vh] mt-auto">
        <div className="flex flex-col space-y-[1.2rem] mb-[4rem]">
          <span className="text-[3rem] text-white font-600"> <Trans i18nKey="connectWalletModal.selectWallet"></Trans></span>
        </div>
        <div className="flex-1 flex w-full flex-col overflow-auto space-y-[1rem]">
          {WALLETS?.map((wallet) => {
            if (!wallet.disabled) {
              return walletItem(wallet);
            }
          })}
          {WALLETS?.map((wallet) => {
            if (wallet.disabled) {
              return walletItem(wallet);
            }
          })}
        </div>
      </div>
    </Modal>
  );
};
