import { contractMatrixBaseAbi } from './contractsAbi/matrixBase';
import { contractNftPassAbi } from './contractsAbi/nftPassAbi';
import { contractNftPromoAbi } from './contractsAbi/nftPromo';
import { contractTierSaleAbi } from './contractsAbi/tierSale';
import { pancakeSwapAbi } from './contractsAbi/pancakeSwap';
import { pollyTokenAbi } from './contractsAbi/pollyToken';

export default {
  allowedChainId: Number(process.env.REACT_APP_ALLOWED_CHAIN_ID),
  apiQraph: process.env.REACT_APP_GRAPH_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  stand: process.env.REACT_APP_STAND,

  contractMatrixBase: process.env.REACT_APP_CONTRACT_MATRIX_B,
  contractMatrixBaseAbi: contractMatrixBaseAbi,

  contractNftPass: process.env.REACT_APP_CONTRACT_NFT_PASS,
  contractNftPassAbi: contractNftPassAbi,

  contractNftPromo: process.env.REACT_APP_CONTRACT_NFT_PROMO,
  contractNftPromoAbi: contractNftPromoAbi,

  contractTiersSale: process.env.REACT_APP_CONTRACT_TIERS_SALE,
  contractTiersSaleAbi: contractTierSaleAbi,

  contractPancake: process.env.REACT_APP_PANCAKE,
  contractPancakeAbi: pancakeSwapAbi,

  contractPollyToken: process.env.REACT_APP_CONTRACT_POLLY_TOKEN,
  contractPollyTokenAbi: pollyTokenAbi,
};
