import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import traslationEn from './locales/en-us/en-us.json';
import traslationEs from './locales/es/es.json';
import traslationAr from './locales/ar/ar.json';
import traslationHi from './locales/hi/hi.json';
import traslationFr from './locales/fr/fr.json';
import traslationRu from './locales/ru/ru.json';
import traslationDe from './locales/de/de.json';
import traslationId from './locales/id/id.json';
import traslationIt from './locales/it/it.json';
import traslationUr from './locales/ur/ur.json';
import traslationZh from './locales/zh/zh.json';
import traslationBn from './locales/bn/bn.json';

const resources = {
  en: {
    translation: traslationEn,
  },
  es: {
    translation: traslationEs,
  },
  fr: {
    translation: traslationFr,
  },
  hi: {
    translation: traslationHi,
  },
  ru: {
    translation: traslationRu,
  },
  ar: {
    translation: traslationAr,
  },
  de: {
    translation: traslationDe,
  },
  id: {
    translation: traslationId,
  },
  it: {
    translation: traslationIt,
  },
  ur: {
    translation: traslationUr,
  },
  zh: {
    translation: traslationZh,
  },
  bn: {
    translation: traslationBn,
  }
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: process.env.REACT_APP_STAND === 'dev',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
