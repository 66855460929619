import React, { useMemo, useState, useEffect } from 'react';
import { PROJECT_STATUS } from '../../../helpers/projectProgress';
import { Tiers } from './Tiers';
import { OpenSale } from './OpenSale';
import { useTiers } from '../../../helpers/hooks/useTiers';
import { useWeb3React } from '@web3-react/core';
import config from '../../../helpers/config';
import { useTranslation } from 'react-i18next';
import { JsonRpcProvider } from '@ethersproject/providers';
import { PUBLIC_URLS } from '../../../helpers/constants';

export const ProjectSteps = ({ currentProjectStatus }) => {
  const { account, chainId, provider } = useWeb3React();
  const [isStartTimerEnd, setStartTimerEnd] = useState(false);
  const { i18n } = useTranslation();

  const { getCurrentTier } = useTiers();
  const renderStep = useMemo(() => {
    switch (currentProjectStatus) {
      case PROJECT_STATUS.TIERS:
        return <Tiers isStartTimerEnd={isStartTimerEnd} setStartTimerEnd={setStartTimerEnd} />;
      case PROJECT_STATUS.PUBLIC_SALE:
        return <OpenSale />;
      case PROJECT_STATUS.MATRIX:
        return null;
      default:
        return null;
    }
  }, [currentProjectStatus, isStartTimerEnd, setStartTimerEnd, i18n.language]);

  const publicProvider = provider ? null : new JsonRpcProvider(PUBLIC_URLS?.[config.allowedChainId]);

  useEffect(() => {
    if (publicProvider && isStartTimerEnd) {
      getCurrentTier();
    }
  }, [publicProvider, isStartTimerEnd]);

  useEffect(() => {
    let interval;
    if (publicProvider && isStartTimerEnd) {
      interval = setInterval(() => {
        getCurrentTier();
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [publicProvider, isStartTimerEnd]);

  useEffect(() => {
    if (!publicProvider && account && chainId === config.allowedChainId && isStartTimerEnd) {
      getCurrentTier();
    }
  }, [publicProvider, account, account, chainId, isStartTimerEnd]);

  useEffect(() => {
    let interval;
    if (!publicProvider && account && chainId === config.allowedChainId && isStartTimerEnd) {
      interval = setInterval(() => {
        getCurrentTier();
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [publicProvider, account, chainId, isStartTimerEnd]);

  return renderStep;
};
