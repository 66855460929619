export const PROJECT_STATUS = {
    TIERS: 'tiers',
    PUBLIC_SALE: 'public sale',
    MATRIX: 'matrix',
}

export const PROJECT_TIMERS = {
    [PROJECT_STATUS.TIERS]: '',
    [PROJECT_STATUS.PUBLIC_SALE]: '',
    [PROJECT_STATUS.MATRIX]: '',
}