import React, { useState } from 'react';
import copyIcon from '../../../../../../assets/icons/main/copy.svg';
import walletIcon from '../../../../../../assets/icons/main/wallet.svg';
import polIcon from '../../../../../../assets/icons/main/polCoin.svg';
import pollyIcon from '../../../../../../assets/icons/main/pollyCoin.svg';
import { copy } from '../../../../../../helpers/copy';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../../../../../helpers/format';
import { Trans } from 'react-i18next';
import polygonScan from '../../../../../../assets/icons/generalIcons/polygonScan.svg';

import { useSelector } from 'react-redux';
import { getBalance } from '../../../../../../store/walletSlice/selectors';

import { useDeactivationWallet } from '../../../../../../helpers/hooks/useDeactivationWallet';
import { Button } from '../../../../../../components/Landing/Button';
import { Link } from 'react-router-dom';
import config from '../../../../../../helpers/config';
import { explorerLinks } from '../../../../../../helpers/constants';
import { callNotification } from '../../../../../../helpers/notification';
import { useTranslation } from 'react-i18next';

export const Wallet = ({ onClose }) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();
 
  const { deactivationWallet } = useDeactivationWallet();
  const { pol: balancePol, polly: balancePolly } = useSelector(getBalance);

  const deactivate = () => {
    onClose();
    deactivationWallet();
  };

  if (!account) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center">
        <span className="text-white text-[2rem] font-500 max-w-[20rem] text-center w-full">
          Сonnect your wallet to continue
        </span>
      </div>
    );
  }

  const addPollyToWallet = async () => {
    const tokenAddress = config.contractPollyToken;
    const tokenSymbol = 'POLLY';
    const tokenDecimals = 18;
    const tokenImage = 'https://polynetica.com/pollyCoin.png';
    
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });
    
      if (wasAdded) {
        callNotification({ type: 'success', message: t('main.POLLYaddedToWallet')})
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="flex flex-col space-y-[2.4rem] w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-[1.6rem]">
          <button className="flex items-center justify-center w-[4rem] h-[4rem] bg-white-80 rounded-[1.2rem]">
            <img src={walletIcon} />
          </button>
          <div className="flex flex-col space-y-[0.4rem]">
            <span className="text-[1.2rem] font-600 text-white">Polygon</span>
            <span className="text-[1.4rem] font-400 text-white opacity-50">{shortenAddress(account)}</span>
          </div>
        </div>
        <button
          onClick={() => copy(account)}
          className="custom-transition flex items-center justify-center w-[4rem] h-[4rem] bg-white-80 rounded-[1.2rem] hover:bg-white-100"
        >
          <img src={copyIcon} />
        </button>
      </div>
      <div className="flex flex-col items-center justify-center space-y-[1.2rem]">
        <div className="flex flex-col items-center space-y-[1.6rem] p-[2.4rem] w-full rounded-[1.2rem] border-[0.1rem] border-solid border-white-100">
          <div className="flex justify-between w-full">
            <div className="flex items-center text-[1.4rem] font-600 leading-[1.96rem] text-white space-x-[0.8rem] ">
              <img src={pollyIcon} />
              <span>{balancePolly}</span>
              <span>POLLY</span>
            </div>
            {/* <span className="text-[1.4rem] text-white opacity-50">$33.8</span> */}
          </div>
          <div className="flex justify-between w-full">
            <div className="flex items-center text-[1.4rem] font-600 leading-[1.96rem] text-white space-x-[0.8rem] ">
              <img src={polIcon} />
              <span>{balancePol}</span>
              <span>POL</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center h-[3.6rem] w-full rounded-[0.8rem] border-[0.1rem] border-white-100 pl-[1.6rem] pr-[0.8rem]">
          <button
            onClick={() => copy(config.contractPollyToken)}
            className="text-[1.2rem] text-white opacity-50 font-500 hover:underline hover:opacity-80 custom-transition"
          >
            {shortenAddress(config.contractPollyToken)}
          </button>
          <Link
            target="blank"
            to={explorerLinks.accountUrl + config.contractPollyToken}
            className="flex space-x-[1.2rem] items-center group"
          >
            <span className="text-[1.2rem] text-white opacity-50 font-500 group-hover:opacity-80 custom-transition">
              Polygonscan
            </span>
            <img src={polygonScan} />
          </Link>
        </div>
      </div>
      <div className="flex flex-col space-y-[1.2rem]">
        <Button onClick={() => addPollyToWallet()} type="purple" className="h-[4.8rem]">
          <Trans i18nKey="main.addPollyInWallet">Add $POLLY to Wallet</Trans>
        </Button>
        <button
          onClick={() => deactivate()}
          className="custom-transition flex items-center justify-center h-[4.8rem] rounded-[1.2rem] bg-white-80 hover:bg-white-100"
        >
          <span className="text-[1.6rem] font-500 text-white">
            <Trans i18nKey="main.disconnectWallet">Disconnect wallet</Trans>
          </span>
        </button>
      </div>
    </div>
  );
};
