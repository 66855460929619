import React, { useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Modal } from '../../../components/Modal';
import { useCheckNft } from '../../../helpers/hooks/useCheckNft';
import { useAuth } from '../../../helpers/hooks/useAuth';
import { Input } from '../../../components/Input';
import copyToClipBoard from 'copy-to-clipboard';

const STATUSES = {
  NOT_FOUND: 'not found',
  FOUND: 'found',
  LOADING: 'loading',
};

export const ActivateNftModal = ({ openedModal, handleCloseModal }) => {
  const [status, setStatus] = useState(STATUSES.LOADING);
  const { account } = useWeb3React();
  const { authAccount, isComplete } = useAuth();
  const { nftCount, isLoadingTelegramCode, telegramCode, checkNft, resetNft, getTelegramCode, resetTelegramCode } =
    useCheckNft();

  useEffect(() => {
    if (openedModal && account) {
      const timer = setTimeout(() => {
        checkNft();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [openedModal, account]);

  useEffect(() => {
    if (nftCount === null) {
      setStatus(STATUSES.LOADING);
    } else {
      if (nftCount) {
        setStatus(STATUSES.FOUND);
      } else {
        setStatus(STATUSES.NOT_FOUND);
      }
    }
  }, [nftCount]);

  const modalClose = () => {
    handleCloseModal();
    resetNft();
    resetTelegramCode();
    setStatus(STATUSES.LOADING);
  };

  useEffect(() => {
    if (isComplete && status === STATUSES.FOUND) {
      getTelegramCode();
    }
  }, [isComplete, status]);

  const getCode = async () => {
    try {
      const result = await authAccount();
    } catch (e) {
      console.log(e);
    }
  };

  const currentState = useMemo(() => {
    switch (status) {
      case STATUSES.FOUND:
        return {
          title: 'NFT Found',
          desc: 'Congratulations! Sign the transaction to access your exclusive Telegram channel with exclusive benefits, including special opportunities, early access, personalized support, and more!',
          img: '/previewPage/nftModal/card.webp',
          btnText: telegramCode.url ? 'Open secret chat' : 'Collect your reward',
          btnFunc: telegramCode.url ? () => window.open(telegramCode.url, '_blank') : () => getCode(),
        };
      case STATUSES.NOT_FOUND:
        return {
          title: 'NFT Not Found ',
          desc: 'Oops, something went wrong. Connect a wallet with a Polynetica Entry Pass.',
          img: '/previewPage/nftModal/not-found.webp',
          btnText: 'Connect other wallet',
          btnFunc: () => modalClose(),
        };
      default:
        return {
          title: 'Loading',
          desc: '',
          img: '/previewPage/nftModal/loader.svg',
          btnText: 'Please wait',
          btnFunc: () => {},
        };
    }
  }, [status, telegramCode]);

  return (
    <Modal isOpened={openedModal} onClose={modalClose}>
      <div className="flex flex-col items-center justify-center lg:items-start bg-white-50 backdrop-blur-[5rem] border border-solid border-white-200 min-h-[57rem] w-[46rem] rounded-[3rem] p-[4rem] pt-[8rem] lg:px-[2rem] lg:pb-[8rem] lg:rounded-none lg:rounded-t-[3rem] lg:w-full lg:min-h-[50vh] mt-auto">
        <div className="h-[20rem] w-full flex items-center justify-center mb-[4rem]">
          <img
            className={`${status === STATUSES.LOADING ? 'h-[6rem] animate-spin' : 'h-full'} ${
              status === STATUSES.FOUND ? 'shadow-previewNftYellow rounded-[2rem]' : ''
            } w-auto`}
            src={currentState.img}
            alt=""
          />
        </div>
        <div className="flex flex-col items-center justify-center text-center w-full">
          <div className="flex flex-col item-center justify-center w-full mb-[4rem] space-y-[1.2rem]">
            <span className="text-[3rem] font-600 text-white">{currentState.title}</span>
            <span className="text-[1.4rem] font-400 text-white-500">{currentState.desc}</span>
          </div>
          {!!telegramCode?.url && (
            <div className="flex w-full relative mb-[1.6rem]">
              <Input readOnly className="w-full" value={telegramCode?.url} />
              <button
                onClick={() => copyToClipBoard(telegramCode.url)}
                className="min-h-[4rem] hover:opacity-80 px-[1.6rem] py-[0.8rem] rounded-[1rem] border border-solid border-[rgba(255,255,255,0.2)] absolute top-1/2 -translate-y-1/2 right-[0.4rem] bg-[#985AFF]"
              >
                <span className="text-[1.4rem] font-700 text-white">Copy</span>
              </button>
            </div>
          )}
          <button
            disabled={status === STATUSES.NOT_FOUND || status === STATUSES.LOADING || isLoadingTelegramCode}
            onClick={() => currentState.btnFunc()}
            className="shadow-previewNftButton bg-[#985AFF] px-[1.6rem] h-[4.8rem] w-full rounded-[1.2rem] border border-solid border-[rgba(255,255,255,0.2)] hover:opacity-80 z-[3] disabled:bg-white-50 disabled:shadow-none disabled:border-white-50"
          >
            <span className="text-white font-500 text-[1.6rem]">{currentState.btnText}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};
