import React from 'react';
import { RefferalBlock } from '../../RefferalBlock';
import { Trans } from 'react-i18next';

export const SaleTitle = ({ uplineAddress }) => {
  return (
    <div className="max-w-[57rem] w-full h-full flex flex-col justify-between space-y-[2.4rem] p-[4rem] lg:p-0 lg:max-w-full">
      <div className="flex flex-col space-y-[2.4rem] lg:items-center lg:text-center">
        <img className="w-[6rem]" src="/logoWithoutText.svg" alt="" />
        <span className="text-[#F0F0F0] text-[6rem] leading-[6.3rem] font-700 space-grotesk lg:text-[4rem] lg:leading-normal">
          $POLLY <br/> Public Sale
        </span>
        <span className="text-[#F0F0F0] opacity-50 text-[1.6rem] font-400 lg:text-[1.4rem]">
          <Trans i18nKey="landing.openSale.leftBar.connectDesc">Connect your wallet and buy $POLLY tokens in Polygon</Trans>
          
        </span>
      </div>
      <RefferalBlock type="public" uplineAddress={uplineAddress} />
    </div>
  );
};
