import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PreviewProjectLayout } from '../../layouts/PreviewProjectLayout';
import { NftBanner } from '../../features/previewPage/NftBanner';
import { GetMoreBtn } from '../../features/previewPage/GetMoreBtn';
import { PreviewPageTimer } from '../../components/Timer/PreviewPageTimer';

export const PreviewPage = () => {
  const [isEndedTimer, setEndedTimer] = useState(false);
  const socials = [
    {
      title: 'telegram',
      img: '/socials/telegram.webp',
      url: 'https://t.me/polynetica',
    },
    {
      title: 'x',
      img: '/socials/x.webp',
      url: 'https://x.com/polynetica',
    },
    {
      title: 'youtube',
      img: '/socials/youtube.webp',
      url: 'https://www.youtube.com/@Polynetica',
    },
  ];

  const polyBlock = (isSmHidden = false) => {
    return (
      <div
        className={`${
          isSmHidden ? 'lg:hidden flex' : 'hidden lg:flex'
        } poly-animation-show lg:w-full items-center justify-center absolute h-[78.4rem] right-[-5%] top-1/2 -translate-y-1/2 lg:relative lg:right-auto lg:top-auto lg:translate-y-0 lg:h-[40rem] lg:min-w-[50rem]`}
      >
        <img
          className="custom-animation-bounce h-[20rem] absolute bottom-[10rem] left-[5rem] z-[3] lg:h-[10rem]"
          src="/previewPage/coin-1.webp"
          alt=""
        />
        <img
          className="custom-animation-bounce h-[15rem] absolute top-[10%] left-[25%] z-[3] lg:h-[7.5rem]"
          src="/previewPage/coin-2.webp"
          alt=""
        />
        <img
          className="custom-animation-bounce h-[25rem] absolute top-[30%] right-[10%] z-[3] lg:h-[12rem]"
          src="/previewPage/coin-3.webp"
          alt=""
        />
        <img
          className="max-h-[80vh] h-full z-[2] min-w-[80rem] lg:min-w-[50rem] w-auto lg:max-h-[51.2rem]"
          src="/previewPage/poly.webp"
          alt=""
        />
        <img
          className="absolute h-auto top-0 left-[-10%] z-[2] w-full z-[0] lg:left-[5%]"
          src="/previewPage/bg-blocks.webp"
          alt=""
        />
      </div>
    );
  };

  const timerItem = (num, isLast = false) => {
    return (
      <>
        <div className="h-[6rem] w-[6rem] flex items-center justify-center bg-black-300 border border-solid border-[#BB92FF] rounded-[1.2rem]">
          <span className="text-[2rem] text-white">{num}</span>
        </div>
        {!isLast && <div className="px-[1.2rem] text-white text-[2rem] animate-pulse">:</div>}
      </>
    );
  };

  return (
    <PreviewProjectLayout>
      <div className="min-h-screen relative flex flex-1 flex-col items-start justify-center pl-[6rem] pr-[4rem] lg:px-[5rem] w-full max-w-[144rem] lg:pt-[4rem] lg:mb-[8rem]">
        <div className="max-w-[76.1%] w-full flex flex-col items-start justify-center lg:items-center lg:max-w-full lg:text-center">
          <div className="w-full flex flex-col items-start justify-center lg:items-center lg:max-w-full lg:text-center">
            <span className="lg:order-2 z-[2] text-white text-[1.4rem] font-light max-w-[40.5rem] w-full mb-[3rem] lg:text-[1.4rem] lg:mb-[1.2rem] lg:max-w-[32.9rem]">
              Welcome to the future of decentralized financial operations with Polynetica, your gateway to seamless
              online business partnerships.
            </span>
            <div className="relative flex flex-col items-start lg:items-center space-grotesk lg:order-1 z-[2] text-[12rem] font-semibold leading-[11rem] text-white mb-[3rem] lg:text-[7rem] lg:leading-[7.4rem] lg:mb-[1.2rem]">
              <span>Polynetica</span>
              <span className="relative lg:flex lg:flex-col lg:space-y-[1.2rem]">
                <span className="gradient-text-purple">Entry Pass</span>
              </span>
            </div>

            <GetMoreBtn className={'hidden lg:flex lg:order-3 lg:my-[1.2rem]'} />
          </div>
          <div className="relative flex flex-col z-[2] space-y-[3rem] lg:items-center">
            <NftBanner className="hidden lg:flex" />
            {polyBlock(false)}
            <div className="flex items-center justify-start space-x-[0.9rem] lg:flex-col lg:space-x-0 lg:space-y-[0.9rem] !mt-0 lg:mt-[3rem]">
              <div className="previewPage-note"></div>
              <span className="space-grotesk text-white text-[1.8rem] leading-[2.4rem]">
                Join us on our social networks. <br /> The story begins there.
              </span>
            </div>
            <div className="flex items-center justify-start space-x-[5.4rem]">
              {socials.map((item, itemIndex) => {
                return (
                  <Link
                    to={item.url}
                    target="_blank"
                    className="group custom-transition hover:grayscale hover:brightness-75 "
                    key={itemIndex}
                  >
                    <img
                      className="custom-transition h-[4rem] w-auto group-hover:-translate-y-[1rem]"
                      src={item.img}
                      alt={item.title}
                    />
                  </Link>
                );
              })}
            </div>
            <img
              className="absolute -right-[20rem] bottom-[-1.25rem] max-h-[10rem] h-full w-auto lg:static lg:right-0 lg:bottom-0"
              src="/previewPage/label.webp"
              alt=""
            />
          </div>
        </div>
        {polyBlock(true)}
      </div>
      <NftBanner className="lg:hidden flex" />

      <footer className="z-[2] w-full pb-[4rem] px-[4rem] flex items-center justify-center">
        <span className="font-light text-[1.4rem] leading-[140%] text-white-500">
          © 2024 Polynetica. All rights reserved.
        </span>
      </footer>
    </PreviewProjectLayout>
  );
};
