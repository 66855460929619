import React from "react";
import { Wallet } from "../BurgerMenu/Sections/Wallet";


export const WalletWidget = ({ isOpened, onClose }) => {

    if (isOpened) {
        return (
            <div className="lg:hidden absolute top-[7.2rem] right-[6.5rem] bg-[#161618] rounded-[2rem] p-[2.4rem] w-[40rem] shadow-headerWidget menu-widget">
              <Wallet onClose={onClose} />
            </div>
        )
    }

    return null;
}