import { useState } from 'react';
import { useGetContract } from './useGetContract';
import { ContractNames } from '../constants';
import { bigIntToInt } from '../numbers';
import BigNumber from 'bignumber.js';

const DEFAULT_TOTAL_DISTRIBUTION = {
  isError: false,
  isLoading: true,
  data: {},
};

const DEFAULT_CLAIM_INFO = {
  isLoading: true,
  isClaiming: false,
  isClaimedNow: false,
  isClaimed: false,
  isError: false,
};

export const useCodeNft = () => {
  const { getContract } = useGetContract();
  const [totalDistribution, setTotalDistribution] = useState(DEFAULT_TOTAL_DISTRIBUTION);

  const [claimInfo, setClaimInfo] = useState(DEFAULT_CLAIM_INFO);

  const resetTotalDistribution = () => {
    setTotalDistribution(DEFAULT_TOTAL_DISTRIBUTION);
  };

  const resetClaimInfo = () => {
    setClaimInfo(DEFAULT_CLAIM_INFO);
  };

  const getTotalDistribution = async (code) => {
    try {
      const contract = await getContract(ContractNames.NFT_PROMO);
      const convertedCode = await contract.convertCode(code);

      if (convertedCode) {
        const result = await contract.totalDistribution(convertedCode);

        if (result) {
          setTotalDistribution({
            isLoading: parseInt(result.total) === 0 ? false : true,
            isError: parseInt(result.total) === 0 ? true : false,
            data: {
              total: parseInt(result.total),
              nftId: parseInt(result.nftId),
              distributed: parseInt(result.distributed),
            },
          });
        }
      }
    } catch (e) {
      console.log(e);
      setTotalDistribution({ ...totalDistribution, isLoading: false, isError: true });
    }
  };

  const checkReceived = async (userAddress, code) => {
    try {
      const contract = await getContract(ContractNames.NFT_PROMO);

      const convertedCode = await contract.convertCode(code);
      if (convertedCode) {
        const result = await contract.userDistribution(userAddress, convertedCode);
        if (result) {
          setClaimInfo({ ...claimInfo, isLoading: false, isClaimed: true });
        } else {
          setClaimInfo({ ...claimInfo, isLoading: false, isClaimed: false });
        }
      }
    } catch (e) {
      console.log(e);
      setClaimInfo({ ...claimInfo, isLoading: false, isError: true });
    }
  };

  const claimNft = async (code) => {
    setClaimInfo({ ...claimInfo, isClaiming: true });
    try {
      const contract = await getContract(ContractNames.NFT_PROMO);
      const convertedCode = await contract.convertCode(code);

      if (convertedCode) {
        const result = await contract.claim(code);

        if (result) {
          setClaimInfo({ ...claimInfo, isClaiming: false, isClaimedNow: true });
        }
      }
    } catch (e) {
      setClaimInfo({ ...claimInfo, isError: true });
      console.log(e);
    }
  };

  return {
    checkReceived,
    getTotalDistribution,
    claimNft,
    resetClaimInfo,
    resetTotalDistribution,
    totalDistribution,
    claimInfo,
  };
};
