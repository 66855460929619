import React, { useEffect, useState } from 'react';
import { TIERS_STATUS } from '../../../../../helpers/tiers';
import { useTiers } from '../../../../../helpers/hooks/useTiers';
import { useCallTransaction } from '../../../../../helpers/hooks/useCallTransaction';
import { Trans } from 'react-i18next';
import { Button } from '../../../../../components/Landing/Button';
import Lottie from 'lottie-react';
import TierFire from '../../../../../assets/lottieAnimations/tierFire.json';
import { callNotification } from '../../../../../helpers/notification';
import { useTranslation } from 'react-i18next';
import { SecondEmptyTimer } from '../../../../../components/Timer/SecondEmptyTimer';
import { BUY_TIMEOUT } from '../../../../../helpers/constants';
import { isSameOrBefore } from '../../../../../helpers/date';
import { fromUnixTime } from 'date-fns';
import { useWeb3React } from '@web3-react/core';
import { ActivateModal } from '../../../../../components/Modals/ActivateModal';
import { useModal } from '../../../../../helpers/hooks/useModal';

export const TierItem = ({ item, uplineAddress, itemIndex }) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { openedModal, onOpen, onClose } = useModal();

  const slotsSold = item.status === TIERS_STATUS.SOLDOUT ? item.info.slots : item.info.slotsSold;
  const { buySlot } = useTiers();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();

  const buySlotFunc = async (price) => {
    setLockBtn(true);
    const result = await buySlot(price, uplineAddress);

    onCallTransaction(result);
    setLockBtn(false);
  };

  useEffect(() => {
    if (transactionInfo.isWaiting) {
      callNotification({ type: 'info', message: t('transaction.progress') });
    }
    if (transactionInfo.isError) {
      setLockBtn(false);
      callNotification({ type: 'error', message: t('transaction.error') });
      resetTransactionInfo();
    }
    if (transactionInfo.isSuccess) {
      setLockBtn(false);
      callNotification({ type: 'success', message: `${t('transaction.purchased')} ${item.info.tokensInSlot} $POLLY` });
      resetTransactionInfo();
    }
  }, [transactionInfo]);

  const [lockBtn, setLockBtn] = useState(false);
  const [intervalTimeBuy, setIntervalTimeBuy] = useState(null);
  const [isComleteIntervalBuy, setIsCompleteIntervalBuy] = useState(false);
  const showTimer = intervalTimeBuy !== null && !isComleteIntervalBuy;

  useEffect(() => {
    const intervalSave = localStorage.getItem('buy_polly_timer');
    if (intervalSave) {
      if (isSameOrBefore(fromUnixTime(intervalSave))) {
        setIsCompleteIntervalBuy(true);
        setIntervalTimeBuy(null);
      } else {
        setIsCompleteIntervalBuy(false);
        setIntervalTimeBuy(intervalSave);
      }
    } else {
      setIsCompleteIntervalBuy(true);
      setIntervalTimeBuy(null);
    }
  }, []);

  const setTimerValue = () => {
    if (!intervalTimeBuy) {
      setIsCompleteIntervalBuy(false);
      setIntervalTimeBuy((Date.now() + BUY_TIMEOUT) / 1000);
      localStorage.setItem('buy_polly_timer', (Date.now() + BUY_TIMEOUT) / 1000);
      setIsCompleteIntervalBuy(false);
    }
  };

  useEffect(() => {
    if (transactionInfo.hash && transactionInfo.isWaiting) {
      setTimerValue();
    }
  }, [transactionInfo]);

  const onCompleteTimer = () => {
    setIsCompleteIntervalBuy(true);
    setIntervalTimeBuy(null);
    setLockBtn(false);
  };

  const renderBuyButton = () => {
    if (transactionInfo.isWaiting || lockBtn) {
      return <div className="loading-animation__black" />;
    }

    if (showTimer) {
      return (
        <SecondEmptyTimer
          time={intervalTimeBuy}
          isCompleted={isComleteIntervalBuy}
          onComplete={() => onCompleteTimer()}
        />
      );
    }

    if (account) {
      return <Trans i18nKey="landing.tiers.rightBar.buyNow">Buy now</Trans>;
    }

    return <Trans i18nKey="main.connectWallet">Connect wallet</Trans>;
  };

  return (
    <div className="relative flex w-full h-[11.6rem]" key={`tier-${itemIndex}`}>
      <div
        className={`flex items-center justify-between relative p-[2rem] bg-[#0C0C0E] border-solid rounded-l-[2rem] rounded-r-[1rem] w-full lg:p-[1.4rem] lg:py-[2rem] ${
          item.status === TIERS_STATUS.ACTIVE
            ? 'border-y-[0.1rem] border-l-[0.1rem] border-[#FCB73E] shadow-[0px_0px_25px_0px_rgba(252,183,62,0.60)_inset]'
            : 'border-[0.1rem] border-white-100'
        }`}
      >
        <div className="flex flex-col justify-between space-y-[2.2rem] lg:h-full">
          <span
            className={`text-[2.4rem] font-500 leading-[3.36rem] lg:text-[1.6rem] lg:leading-[2.24rem] ${
              item.status === TIERS_STATUS.SOLDOUT ? 'text-white opacity-20' : 'text-[#FCB73E]'
            }`}
          >
            {item.info.tokensInSlot} POLLY
          </span>
          <div className="flex items-center space-x-[0.8rem] lg:flex-col lg:items-start lg:space-x-0 lg:space-y-[0.5rem]">
            <div
              className={`flex space-x-[0.4rem] text-white text-[1.4rem] min-w-[7rem] lg:text-[1rem] ${
                item?.status === TIERS_STATUS.SOLDOUT && 'opacity-20'
              }`}
            >
              <span>{slotsSold.toFixed(0)}</span>
              <span>/</span>
              <span className="opacity-50">{item.info.slots}</span>
            </div>

            <div className="relative bg-white-70 w-full max-w-[14.4rem] h-[0.8rem] rounded-full overflow-hidden lg:max-w-[13.5rem]">
              <div
                style={{ width: `${(slotsSold.toFixed(0) / item.info.slots) * 100}%` }}
                className="absolute top-0 left-0 h-full bg-[#FCB73E] rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end space-y-[1.6rem] text-[1.4rem] text-white font-500 lg:text-[1rem] lg:h-full lg:justify-between">
          <div className="flex space-x-[0.4rem] lg:flex-col lg:space-x-0 lg:space-y-[0.4rem] lg:items-end">
            <span className="opacity-40 whitespace-nowrap">
              <Trans i18nKey="landing.tiers.rightBar.pricePerToken">Price per Token</Trans>
            </span>
            <span className={`${item.status === TIERS_STATUS.SOLDOUT ? ' text-[#FCB73E]' : 'text-white'}`}>
              {item.info.pricePerToken}
            </span>
          </div>
          {item.status === TIERS_STATUS.SOLDOUT && (
            <div
              onClick={() => setTimerValue()}
              className="z-[5] flex items-center justify-center h-[4rem] w-[17.6rem] rounded-[1.2rem] bg-[rgba(252,183,62,0.10)] border-solid border-[0.1rem] border-[#FCB73E] lg:rounded-[1.6rem] lg:h-[2.6rem] lg:w-full"
            >
              <span className="text-[1.6rem] font-500 text-[#FCB73E] lg:text-[1.4rem]">
                <Trans i18nKey="landing.tiers.rightBar.soldout">Sold out</Trans>
              </span>
            </div>
          )}
          {item.status === TIERS_STATUS.ACTIVE && (
            <Button
              type="orange"
              disabled={transactionInfo.isWaiting || !isComleteIntervalBuy || lockBtn}
              onClick={account ? () => buySlotFunc(item.info.price) : () => onOpen()}
              className="z-[5] flex items-center justify-center h-[4rem] w-[17.6rem] whitespace-nowrap lg:h-[2.6rem] lg:w-full disabled:opacity-80"
            >
              {renderBuyButton()}
            </Button>
          )}
          {item.status === TIERS_STATUS.SOON && (
            <div className="z-[5] flex items-center justify-center h-[4rem] w-[17.6rem] rounded-[1.2rem] bg-white-50 border-solid border-[0.1rem] border-white-50 lg:rounded-[1.6rem] lg:h-[2.6rem] lg:w-full">
              <span className="lg:hidden text-[1.6rem] font-500 text-white-500 lg:text-[1.4rem]">
                <Trans i18nKey="main.comingSoon">Coming soon</Trans>
              </span>
              <span className="hidden lg:inline text-[1.6rem] font-500 text-white-500 lg:text-[1.4rem]">
                <Trans i18nKey="main.soon">Soon</Trans>
              </span>
            </div>
          )}
        </div>
        {item.status === TIERS_STATUS.ACTIVE && (
          <>
            <Lottie
              className="z-[3] h-[12rem] rotate-[60deg] absolute bottom-[-6rem] left-[2rem]"
              animationData={TierFire}
              loop={true}
            />
            <Lottie
              className="z-[3] h-[12rem] rotate-[60deg] absolute top-[-6rem] right-[4rem]"
              animationData={TierFire}
              loop={true}
            />
            <Lottie
              className="z-[3] h-[10rem] rotate-[-30deg] absolute top-1/2 -translate-y-1/2 right-[-20rem] lg:right-[-14rem]"
              animationData={TierFire}
              loop={true}
            />
          </>
        )}
      </div>
      <div
        className={`flex items-center justify-center ${
          (item.status === TIERS_STATUS.SOLDOUT &&
            'bg-[rgba(252,183,62,0.05)] text-[#FCB73E] border-dashed border-[0.1rem] border-[#FCB73E]') ||
          (item.status === TIERS_STATUS.ACTIVE &&
            'bg-[#FCB73E] text-black border-l-[0.1rem] border-dashed border-black') ||
          (item.status === TIERS_STATUS.SOON && 'bg-white-50 text-white text-opacity-30')
        } rounded-r-[2rem] rounded-l-[1rem] max-w-[14rem] w-full lg:max-w-[8.5rem]`}
      >
        <span className="text-center text-[2.4rem] font-600 space-grotesk lg:text-[1.6rem]">{item.info.price} POL</span>
      </div>
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
