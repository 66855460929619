import React from 'react';
import config from './helpers/config';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PreviewPage } from './pages/PreviewPage';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Program } from './pages/Program';
import { Level } from './pages/Level';
import { Landing } from './pages/Landing';

export const RoutesFind = () => {
  const devRoutes = () => {
    return (
      <Routes>
        {/* <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/program" element={<Program />} />
        <Route path="/level" element={<Level />} /> */}
        <Route path="/" element={<Landing />} />
        <Route path="/entryPass" element={<PreviewPage />} />
      </Routes>
    );
  };

  const prodRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/entryPass" element={<PreviewPage />} />
      </Routes>
    );
  };

  return <Router>{config.stand === 'prod' ? prodRoutes() : devRoutes()}</Router>;
};
