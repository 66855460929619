import React from 'react';
import { Title } from '../../../components/Landing/Title';
import { Item } from './item';
import holdIcon from '../../../assets/icons/landing/economy/holdIcon.svg';
import { Trans } from 'react-i18next';

export const Economy = () => {
  const energyList = [
    {
      title: <Trans i18nKey="landing.economy.dividend.energynfts.power.title">Unleash the Power of Energies</Trans>,
      desc: (
        <Trans i18nKey="landing.economy.dividend.energynfts.power.desc">
          Energies are unique NFTs that unlock a world of earning potential.
        </Trans>
      ),
      video: '1',
      videoPostition: 'lg:absolute',
    },
    {
      title: <Trans i18nKey="landing.economy.dividend.energynfts.earning.title">Earn Energies</Trans>,
      desc: (
        <Trans i18nKey="landing.economy.dividend.energynfts.earning.desc">
          Participate in programs to earn Energies. The higher your contribution, the more you earn.
        </Trans>
      ),
      video: '2',
      videoPostition: 'absolute',
    },
    {
      title: <Trans i18nKey="landing.economy.dividend.energynfts.passiveincome.title">Unlock Passive Income</Trans>,
      desc: (
        <Trans i18nKey="landing.economy.dividend.energynfts.passiveincome.desc">
          Energy provides income for 7 days. Holders with active PIDs are eligible to dividends.
        </Trans>
      ),
      video: '3',
      videoPostition: 'absolute',
    },
  ];
  return (
    <div className="flex flex-col space-y-[8rem] items-center justify-center w-full mt-[20rem] lg:mt-[7.8rem] lg:space-y-[4rem]">
      <Title
        title={<Trans i18nKey="landing.economy.title">Circular Economy</Trans>}
        className="text-white text-[6rem] leading-[6.6rem]"
        desc={
          <Trans i18nKey="landing.economy.desc">
            Automatic profit distribution stabilizes the POLLY token and incentivizes participation
          </Trans>
        }
      />
      <div className="flex flex-col space-y-[8rem] w-full lg:space-y-[4rem]">
        <div className="flex flex-col space-y-[2.4rem]">
          <span className="text-[1.8rem] font-600 text-white uppercase">
            <Trans i18nKey="landing.economy.dividend.distribution.title">Dividend Distribution</Trans>
          </span>
          <div className="flex flex-col items-center space-y-[1.2rem] lg:w-full">
            <div className="flex space-x-[1.2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem] lg:w-full">
              <Item
                desc={
                  <Trans
                    i18nKey="landing.economy.dividend.distribution.item1"
                    defaults="<whiteText>50% of fees</whiteText> go to a development fund."
                    components={{ whiteText: <span className="text-white" /> }}
                  />
                }
                content={
                  <>
                    <span className="text-[2.2rem] font-600 space-grotesk text-white-500 space-grotesk">
                      <span className="text-white">50</span>%
                    </span>
                  </>
                }
              />
              <Item
                desc={
                  <Trans
                    i18nKey="landing.economy.dividend.distribution.item2"
                    defaults="<whiteText>25% are used to buy back POLLY</whiteText> tokens."
                    components={{ whiteText: <span className="text-white" /> }}
                  />
                }
                content={
                  <>
                    <span className="text-[2.2rem] font-600 space-grotesk text-white-500 space-grotesk">
                      <span className="text-white">25</span>%
                    </span>
                  </>
                }
              />
              <Item
                desc={
                  <Trans
                    i18nKey="landing.economy.dividend.distribution.item3"
                    defaults="<whiteText>5% are used to buy back</whiteText> and burn POLLY tokens."
                    components={{ whiteText: <span className="text-white" /> }}
                  />
                }
                content={
                  <>
                    <span className="text-[2.2rem] font-600 space-grotesk text-white-500 space-grotesk">
                      <span className="text-white">5</span>%
                    </span>
                  </>
                }
              />
            </div>
            <div className="flex space-x-[1.2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem] lg:w-full">
              <Item
                desc={
                  <Trans
                    i18nKey="landing.economy.dividend.distribution.item4"
                    defaults="<whiteText>15% are used to</whiteText> distribute dividends to NFT holders."
                    components={{ whiteText: <span className="text-white" /> }}
                  />
                }
                content={
                  <>
                    <span className="text-[2.2rem] font-600 space-grotesk text-white-500 space-grotesk">
                      <span className="text-white">15</span>%
                    </span>
                  </>
                }
              />
              <Item
                desc={
                  <Trans
                    i18nKey="landing.economy.dividend.distribution.item5"
                    defaults="<whiteText>5% are dedicated</whiteText> to promo and marketing."
                    components={{ whiteText: <span className="text-white" /> }}
                  />
                }
                content={
                  <>
                    <span className="text-[2.2rem] font-600 space-grotesk text-white-500 space-grotesk">
                      <span className="text-white">5</span>%
                    </span>
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-[2.4rem]">
          <span className="text-[1.8rem] font-600 text-white uppercase">
            <Trans i18nKey="landing.economy.dividend.eligibility.title">Dividend Eligibility</Trans>
          </span>
          <div className="flex space-x-[1.2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
            <Item
              desc={
                <Trans
                  i18nKey="landing.economy.dividend.eligibility.item1"
                  defaults="<whiteText>Get a unique</whiteText> Polynetica ID (PID)."
                  components={{ whiteText: <span className="text-white" /> }}
                />
              }
              content={
                <>
                  <span className="text-[2.8rem] font-600 space-grotesk text-white-300 space-grotesk">
                    P<span className="text-white">ID</span>
                  </span>
                </>
              }
            />
            <Item
              desc={
                <Trans
                  i18nKey="landing.economy.dividend.eligibility.item2"
                  defaults="<whiteText>Hold at least 0.001%</whiteText> of the total token supply."
                  components={{ whiteText: <span className="text-white" /> }}
                />
              }
              content={
                <>
                  <img src={holdIcon} />
                </>
              }
            />
            <Item
              desc={
                <Trans
                  i18nKey="landing.economy.dividend.eligibility.item3"
                  defaults="<whiteText>Keep tokens in your account</whiteText> for at least 7 days."
                  components={{ whiteText: <span className="text-white" /> }}
                />
              }
              content={
                <div className="flex flex-col items-center justify-center font-600 text-white space-grotesk">
                  <span className="leading-none text-[2.4rem]">7</span>
                  <span className="leading-none text-[1.4rem] opacity-50">DAYS</span>
                </div>
              }
            />
          </div>
        </div>
        <div className="flex flex-col justify-center space-y-[2.4rem] h-full">
          <span className="text-[1.8rem] font-600 text-white uppercase">Energy NFTs</span>
          <div className="hide-scroll flex items-start justify-center h-[50rem] space-x-[1.2rem] lg:overflow-auto lg:justify-start lg:h-[46rem]">
            {energyList.map((item, itemIndex) => {
              return (
                <div
                  className="flex flex-col relative items-center overflow-hidden justify-between h-full w-full p-[4rem] space-y-[1.6rem] rounded-[2rem] border-solid border-[0.1rem] border-white-100 shadow-[0px_0px_35px_0px_rgba(255,255,255,0.10)_inset] lg:min-w-[30rem] lg:p-[2rem] lg:py-[4rem]"
                  key={itemIndex}
                >
                  <div className="flex flex-col items-center text-center space-y-[1.6rem] z-[1]">
                    <span className="text-[2rem] font-500 leading-[2.2rem] text-white">{item?.title}</span>
                    <span className="text-[1.4rem] font-400 leading-[1.96rem] text-white opacity-50 max-w-[98%]">
                      {item?.desc}
                    </span>
                  </div>
                  <video
                    preload="none"
                    className={`${item?.videoPostition} bottom-0 z-[-1]`}
                    style={{ objectFit: 'cover', width: '100%' }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={`/images/landing/economy/${item.video}.mp4`} type="video/mp4" />
                  </video>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
