import React from 'react';
import { useInView } from 'react-intersection-observer';

export const ScrollAnimation = ({ children, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  return (
    <div ref={ref} className={`w-full scroll-animation ${inView ? 'animate' : ''} ${className}`}>
      {children}
    </div>
  );
};
