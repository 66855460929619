import React, { useState, useRef } from 'react';
import arrowIcon from '../../assets/icons/landing/programs/faqList/arrowIcon.svg';
import { useTranslation } from 'react-i18next';
import { getLanguageName } from '../../helpers/languages';
import { useOnClickOutside } from '../../helpers/hooks/useOnClickOutside';

export const LanguageSelector = () => {
  const [isOpened, setIsOpened] = useState(false);

  const { i18n } = useTranslation();
  const ref = useRef();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpened(false);
  };

  useOnClickOutside(ref, () => {
    setIsOpened(false);
  })

  return (
    <div className="relative h-[4.4rem] w-[17.4rem]" ref={ref}>
      {isOpened && (
        <div className="rounded-[1.2rem] backdrop-blur-[2rem] bg-[#222224] absolute top-[-18.6rem] w-full flex flex-col w-[17.4rem] h-[18.2rem] px-[1.2rem] shadow-headerWidget overflow-auto">
          {Object.keys(i18n.options.resources).map((item, itemIndex) => {
            const title = getLanguageName(item);
            return (
              <button
                className="group flex-1 flex items-center justify-start w-full py-[0.6rem] first:pt-[1rem] last:pb-[1rem] disabled:opacity-50 disabled:pointer-events-none"
                onClick={() => changeLanguage(item)}
                key={itemIndex}
                disabled={title === getLanguageName(i18n.resolvedLanguage)}
              >
                <span className="text-white-500 text-[1.4rem] font-500 group-hover:text-white">{title}</span>
              </button>
            );
          })}
        </div>
      )}
      <button
        onClick={() => setIsOpened((value) => !value)}
        className="group custom-transition flex items-center justify-between space-x-[6rem] bg-white-50 rounded-[1.2rem] h-full w-full px-[1.2rem] hover:bg-white-70"
      >
        <span className="custom-transition text-[1.6rem] font-500 text-white-500 group-hover:text-white">{getLanguageName(i18n.resolvedLanguage)}</span>
        <img className={`custom-transition w-[1.4rem] h-[1.4rem] ${isOpened ? 'rotate-180' : ''}`} src={arrowIcon} />
      </button>
    </div>
  );
};
