import React from 'react';
import { toast } from 'react-toastify';
import sucessIcon from '../assets/icons/notifications/sucessIcon.svg';
import informationIcon from '../assets/icons/notifications/informationIcon.svg';
import errorIcon from '../assets/icons/notifications/errorIcon.svg';
import closeIcon from '../assets/icons/main/close.svg';

const defaultStyles =
  '!flex !items-center !justify-between notification-bg !rounded-[1.2rem] !border-[0.1rem] !border-solid !border-white-100 !text-[1.4rem] !font-500 !h-[6.4rem] !w-[35.8rem] !leading-[1.96rem] sm:!left-1/2 sm:!-translate-x-1/2 sm:mb-[0.5rem] sm:!top-[2rem]';

const iconsByType = {
  success: <img src={sucessIcon} className="w-[2.4rem] h-[2.4rem]" />,
  error: <img src={errorIcon} className="w-[2.4rem] h-[2.4rem]" />,
  info: <img src={informationIcon} className="w-[2.4rem] h-[2.4rem]" />,
};

const renderMessage = (type, message) => {
  switch (type) {
    case 'success':
      return <span className="text-[#CEFA2F]">{message}</span>;
    case 'error':
      return <span className="text-[#FFAF03]">{message}</span>;
    case 'info':
    default:
      return <span className="text-white-500 text-[1.2rem] leading-[1.68rem] font-400">{message}</span>;
  }
};

export const callNotification = ({ type = 'success', message = '', ...props }) => {
  const isServer = typeof window === 'undefined';

  if (isServer) return;

  return toast(
    <div className="flex items-center space-x-[2rem]">
      {iconsByType[type]}
      <div>{renderMessage(type, message)}</div>
    </div>,
    {
      position: window.innerWidth <= 767 ? 'top-center' : 'bottom-left',
      className: defaultStyles,
      icon: false,
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: <img src={closeIcon} className="!absolute !right-[2rem] !w-[1.6rem] !h-[1.6rem]" />,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      ...props,
    },
  );
};
