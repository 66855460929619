import React from 'react';

export const Item = ({ content, desc }) => {
  return (
    <div className="flex items-center space-x-[2rem] rounded-[2rem] w-full max-w-[39.2rem] h-[10.6rem] p-[1.3rem] journeyList-bg z-[1] lg:max-w-full">
      <div className="flex flex-col items-center justify-center w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
        {content}
      </div>
      <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[75%]">{desc}</span>
    </div>
  );
};
