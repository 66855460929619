import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import { FaqItem } from './item';
import { Trans } from 'react-i18next';

export const FaqList = () => {
  const faqInfo = [
    {
      title: (<Trans i18nKey="landing.faq.questions.pid.title">What is a PID?</Trans>),
      content: (<Trans i18nKey="landing.faq.questions.pid.content">A PID is a unique numerical identifier assigned to every wallet interacting within the Polynetica ecosystem.</Trans>),
    },
    {
      title: (<Trans i18nKey="landing.faq.questions.circulationNode.title">The Circulation Node (PID 1)</Trans>),
      content: (<Trans i18nKey="landing.faq.questions.circulationNode.content">The Circulation Node (PID1) serves as the central hub for all new partnerships. It initiates mandatory registrations and distributes a portion of its profits back into the protocol, creating a self-sustaining economic cycle.</Trans>),
    },
    {
      title: (<Trans i18nKey="landing.faq.questions.newParticipant.title">New Participant IDs</Trans>),
      content: (<Trans i18nKey="landing.faq.questions.newParticipant.content">Each new wallet joining the Polynetica ecosystem is assigned a sequential PID (PID2, PID3, and so on). This ensures a clear and organized system for tracking user activity within the platform.</Trans>),
    },
  ];
  return (
    <div className="relative flex items-center justify-between p-[4rem] pl-[8rem] bg-[#020204] w-full rounded-[3rem] border-dashed border-[0.1rem] border-white-200 overflow-hidden lg:p-[2rem] lg:pl-[2rem] lg:flex-col">
      <div className="flex flex-col space-y-[2rem] lg:flex-row lg:space-y-0 lg:items-center lg:justify-between lg:w-full">
        <div className="flex items-center justify-center w-[5.8rem] h-[5.8rem] bg-white-50 border-solid border-[0.1rem] border-white-50 rounded-[1.6rem] shadow-[0px_14px_10px_0px_rgba(0,0,0,0.50)]">
          <span className="text-[2.8rem] font-600 space-grotesk text-white-300 space-grotesk no_translate">
            P<span className="text-white">ID</span>
          </span>
        </div>
        <span className="text-[6rem] font-600 text-white space-grotesk  lg:text-[2.4rem]">Polynetica IDs</span>
      </div>
      <Accordion allowZeroExpanded allowMultipleExpanded className="space-y-[1.2rem] lg:mt-[3.2rem] z-[111] lg:w-full ">
        {faqInfo.map((item, itemIndex) => {
          return <FaqItem {...item} key={itemIndex} />;
        })}
      </Accordion>
      <img
        className="absolute left-0 top-0 z-[0] lg:h-[25%] lg:w-full"
        src="/images/landing/programs/faqList/shadow.webp"
      />
    </div>
  );
};
