import { useState } from 'react';
import { useGetContract } from './useGetContract';
import { ContractNames } from '../constants';
import { increaseByPercent } from '../numbers';
import { DEFAULT_GAS_LIMIT } from '../constants';
import { toWei } from '../numbers';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

export const useOpenSale = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [polyValue, setOpenValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getPolyValue = async (polValue = 0) => {
    setIsLoading(true);
    const value = Number(polValue) ? toWei(polValue) : 0;
    try {
      const contract = await getContract(ContractNames.TIERS_SALE);
      const result = await contract.tokensToReceiveFrontEnd(value);
      if (result) {
        setOpenValue((parseInt(result) / 1e18) * 0.98);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const openSaleBuy = async (valueFrom, polyValue, slippageValue = 0.5, refAddress = '0x0000000000000000000000000000000000000000') => {
    setIsLoading(true);
    const finalPolyValue = (polyValue - polyValue * (slippageValue / 100));
    const poly = toWei(finalPolyValue.toFixed(6));

    const upline =
      refAddress !== undefined ? refAddress || account === refAddress : '0x0000000000000000000000000000000000000000';
    try {
      const contract = await getContract(ContractNames.TIERS_SALE);

      let gas = null;
      try {
        gas = await contract.estimateGas.publicSale(upline, poly, {
          value: toWei(parseFloat(valueFrom)),
        });
      } catch (e) {
        console.log(e);
      }

      return await contract.publicSale(upline, poly, {
        gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
        value: toWei(parseFloat(valueFrom)),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    polyValue,
    isLoading,
    openSaleBuy,
    getPolyValue,
  };
};
