import { useState } from 'react';
import { useGetContract } from './useGetContract';
import { ContractNames } from '../constants';
import { bigIntToInt } from '../numbers';
import { ALL_TIERS_STATUS, TIERS, TIERS_STATUS } from '../tiers';
import { increaseByPercent } from '../numbers';
import { DEFAULT_GAS_LIMIT } from '../constants';
import { toWei } from '../numbers';
import { callNotification } from '../notification';
import { parseErrorToUserReadableMessage } from '../format';

import { useDispatch } from 'react-redux';
import { updateTiers } from '../../store/tierSlice';
import { useWeb3React } from '@web3-react/core';

import { JsonRpcProvider } from '@ethersproject/providers';
import { PUBLIC_URLS } from '../constants';
import config from '../config';

const DEFAULT_TIER_INFO = {
  index: 0,
  status: TIERS_STATUS.SOON,
  info: {
    slots: 0,
    maxTokens: 0,
    price: 0,
    refBonus: 0,
    tokensInSlot: 0,
    tokensSold: 0,
  },
};

const DEFAULT_REF_INFO = {
  totalReferrals: 0,
  totalRewards: 0,
  refBonusToSend: 0,
  currentReferral: null,
  exists: null,
};

export const useTiers = () => {
  const { account, provider } = useWeb3React();
  const { getContract } = useGetContract();
  const [isFirstLoadTier, setIsFirstLoadTier] = useState(true);
  const [isEndedTiers, setEndedTiers] = useState(false);
  const [currentTier, setCurrentTier] = useState(DEFAULT_TIER_INFO);
  const [allTiers, setAllTiers] = useState(TIERS);
  const [userRefInfo, setUserRefInfo] = useState(DEFAULT_REF_INFO);

  const currentProvider = provider ? provider : new JsonRpcProvider(PUBLIC_URLS?.[config.allowedChainId]);

  const dispatch = useDispatch();

  const partnerRegistration = async (address) => {
    try {
      const contract = await getContract(ContractNames.TIERS_SALE);
      const result = await contract.registration(address);

      if (result) {
        return result;
      }
    } catch (e) {
      callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
    }
  };

  const getUserRefInfo = async (address) => {
    try {
      const contract = await getContract(ContractNames.TIERS_SALE);
      const result = await contract.users(address);

      if (result) {
        setUserRefInfo({
          currentReferral: result?.currentReferral || null,
          exists: result?.exists || false,
          totalReferrals: parseInt(result?.totalReferrals) || 0,
          totalRewards: (parseInt(result?.totalRewards) / 1e18).toFixed(0) || 0,
          refBonusToSend: (parseInt(result?.refBonusToSend) / 1e18).toFixed(0) || 0,
        });
      }
    } catch (e) {
      console.log(e);
      // callNotification({ type: 'error', message: `refInfo fetch - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const getCurrentTier = async () => {
    try {
      const contract = await getContract(ContractNames.TIERS_SALE, currentProvider);
      const result = await contract.getCurrentTierIndex();

      if (result) {
        const num = parseInt(result);
        if (num !== 100) {
          getTierInfo(parseInt(result));
        } else {
          dispatch(updateTiers({ allStatus: ALL_TIERS_STATUS.ENDED, currentTier: null }));

          let temp = TIERS.slice();
          temp = temp.map((item) => {
            return { ...item, status: TIERS_STATUS.SOLDOUT };
          });
          setAllTiers(temp);
        }
      }
    } catch (e) {
      console.log(e);
      // callNotification({ type: 'error', message: `balance t.index - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const getTierInfo = async (index) => {
    try {
      const contract = await getContract(ContractNames.TIERS_SALE, currentProvider);
      const result = await contract.tiers(index);

      dispatch(
        updateTiers({
          allStatus: ALL_TIERS_STATUS.SALE,
          currentTier: {
            index: index,
            status: TIERS_STATUS.ACTIVE,
            info: {
              slots: bigIntToInt(result.maxTokens) / bigIntToInt(result.tokensInSlot),
              maxTokens: bigIntToInt(result.maxTokens),
              price: bigIntToInt(result.price),
              pricePerToken: TIERS[index].info.pricePerToken,
              refBonus: bigIntToInt(result.refBonus),
              tokensInSlot: bigIntToInt(result.tokensInSlot),
              tokensSold: bigIntToInt(result.tokensSold) || 0,
              slotsSold: bigIntToInt(result.tokensSold) / bigIntToInt(result.tokensInSlot) || 0,
            },
          },
        }),
      );
      setIsFirstLoadTier(false);
    } catch (e) {
      // callNotification({ type: 'error', message: `balance fetch - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const buySlot = async (value, refAddress = '0x0000000000000000000000000000000000000000') => {
    const upline = !!refAddress && account !== refAddress ? refAddress : '0x0000000000000000000000000000000000000000';

    try {
      const contract = await getContract(ContractNames.TIERS_SALE);
      let gas = null;
      try {
        gas = await contract.estimateGas.buy(upline, {
          value: toWei(parseFloat(value)),
        });
      } catch (e) {
        console.log(e);
        // callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
      }

      return await contract.buy(upline, {
        gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
        gasPrice: 90000000000,
        value: toWei(parseFloat(value)),
      });
    } catch (e) {
      callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
    }
  };

  return {
    isFirstLoadTier,
    allTiers,
    setAllTiers,
    isEndedTiers,
    buySlot,
    currentTier,
    getCurrentTier,
    userRefInfo,
    getUserRefInfo,
    partnerRegistration,
  };
};
