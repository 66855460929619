import React, { useEffect, useState, useMemo } from 'react';
import { copy } from '../../../../helpers/copy';
import { useWeb3React } from '@web3-react/core';
import { useTiers } from '../../../../helpers/hooks/useTiers';
import config from '../../../../helpers/config';
import CircleInfoIcon from '../../../../assets/icons/main/circleInfo.svg';
import { TIERS } from '../../../../helpers/tiers';
import { useCallTransaction } from '../../../../helpers/hooks/useCallTransaction';
import { callNotification } from '../../../../helpers/notification';
import { shortenAddress } from '../../../../helpers/format';

import { useSelector } from 'react-redux';
import { getBalance } from '../../../../store/walletSlice/selectors';
import { getTiersInfo } from '../../../../store/tierSlice/selectors';

import { useTranslation, Trans } from 'react-i18next';

import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { explorerLinks, ZERO_ADDRESS } from '../../../../helpers/constants';

const TYPES = {
  TIERS: 'tiers',
  PUBLIC: 'public',
};

export const RefferalBlock = ({ type = TYPES.TIERS, uplineAddress }) => {
  const [isFirstLoaded, setIsFirstLoaded] = useState(true);
  const { account, chainId } = useWeb3React();
  const locationInfo = new URL(window.location.toString());
  const { getUserRefInfo, userRefInfo, partnerRegistration } = useTiers();
  const { transactionInfo, onCallTransaction, resetTransactionInfo } = useCallTransaction();

  const { pol: balancePol } = useSelector(getBalance);
  const { currentTier } = useSelector(getTiersInfo);

  const { t, i18n } = useTranslation();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const locationParams = new URLSearchParams(document.location.search);
  let isHaveRef = !!locationParams.get('ref') || !!uplineAddress;

  const refLink = window.location ? `${locationInfo.href.replace(locationInfo.search, '')}?ref=${account}` : '';
  const colorStyles =
    type === TYPES.TIERS
      ? { bg: 'bg-[#FCB73E]', text: 'text-[#FCB73E]' }
      : { bg: 'bg-[#985AFF]', text: 'text-[#985AFF]' };

  useEffect(() => {
    if (account && chainId === config.allowedChainId && isFirstLoaded && inView) {
      getUserRefInfo(account);
      setIsFirstLoaded(false);
    }
  }, [account, chainId, isFirstLoaded, inView]);

  useEffect(() => {
    if (account && chainId === config.allowedChainId && !isFirstLoaded && inView) {
      setIsFirstLoaded(true);
      getUserRefInfo(account);
      setIsFirstLoaded(false);
    }
  }, [account, chainId, isFirstLoaded, inView]);

  useEffect(() => {
    let interval;
    if (account && chainId === config.allowedChainId && inView && !isFirstLoaded && !document?.hidden) {
      interval = setInterval(() => {
        getUserRefInfo(account);
      }, 20000);
    }
    return () => clearInterval(interval);
  }, [account, chainId, inView, isFirstLoaded, document]);

  const refRegister = async () => {
    if (uplineAddress === account) {
      callNotification({ type: 'error', message: t('landing.refferalProgramInfo.notification.invalidLink') });
    } else {
      if (balancePol > 0) {
        const result = await partnerRegistration(uplineAddress);
        onCallTransaction(result);
      } else {
        callNotification({ type: 'error', message: t('landing.refferalProgramInfo.notification.noBalance') });
      }
    }
  };

  const renderRefbar = useMemo(() => {
    if (userRefInfo.refBonusToSend > 0) {
      return (
        <div className="flex items-center justify-center w-full space-x-[1.2rem] lg:flex-col lg:w-full lg:space-x-0 lg:space-y-[1.2rem]">
          <div className="rounded-[1.2rem] min-w-[11.4rem] h-[8.4rem] p-[1.6rem] flex flex-col items-start justify-between border border-dashed border-white-100 lg:w-full">
            <span className={`${colorStyles.text} text-[1.2rem] font-light`}>
              {t('landing.refferalProgramInfo.refCount')}
            </span>
            <span className="text-white text-[1.8rem] font-semibold">{userRefInfo.totalReferrals}</span>
          </div>
          <div className="rounded-[1.2rem] flex-1 h-[8.4rem] p-[1.6rem] flex items-center justify-between bg-[#111113] border border-solid border-white-100 lg:w-full lg:flex-auto">
            <div className="h-full flex flex-col items-start justify-between">
              <span className={`${colorStyles.text} text-[1.2rem] font-light `}>
                {t('landing.refferalProgramInfo.lostReward')}
              </span>
              <div className="flex items-center space-x-[0.8rem]">
                {type === TYPES.PUBLIC && <img className="w-[2.4rem] h-[2.4rem]" src="/icons/currency/poly.svg" />}
                <span className="text-white text-[1.8rem] font-semibold">{userRefInfo.refBonusToSend}</span>
              </div>
            </div>
            <button
              className={`px-[1.6rem] py-[0.8rem] w-[12.3rem] h-[4rem] rounded-[1.2rem] flex items-center justify-center ${colorStyles.bg}`}
            >
              <span className="text-[#020204] text-[1.6rem] font-500">{t('landing.refferalProgramInfo.buyNow')}</span>
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center w-full space-x-[1.2rem]">
        <div className="rounded-[1.2rem] flex-1 h-[8.4rem] p-[1.6rem] flex flex-col items-start justify-between bg-[#111113] border border-solid border-white-100">
          <span className={`${colorStyles.text} text-[1.2rem] font-light`}>
            {t('landing.refferalProgramInfo.refCount')}
          </span>
          <span className="text-white text-[1.8rem] font-semibold">{userRefInfo.totalReferrals}</span>
        </div>
        <div className="rounded-[1.2rem] flex-1 h-[8.4rem] p-[1.6rem] flex flex-col items-start justify-between bg-[#111113] border border-solid border-white-100">
          <span className={`${colorStyles.text} text-[1.2rem] font-light`}>
            {t('landing.refferalProgramInfo.totalReward')}
          </span>
          <div className="flex items-center space-x-[0.8rem]">
            {type === TYPES.PUBLIC && <img className="w-[2.4rem] h-[2.4rem]" src="/icons/currency/poly.svg" />}
            <span className="text-white text-[1.8rem] font-semibold">{userRefInfo.totalRewards}</span>
          </div>
        </div>
      </div>
    );
  }, [userRefInfo, colorStyles, t]);

  return (
    <div
      className="relative flex flex-col items-center space-y-[2.4rem] rounded-[2.4rem] w-full p-[2rem] border-dashed border-[0.1rem] border-white-200 bg-white-50 lg:rounded-[1rem]"
      ref={ref}
    >
      <div className="flex flex-col space-y-[1.6rem] w-full">
        <div className="flex items-center justify-start space-x-[1rem]">
          <span className="text-[2rem] font-600 leading-[2.2rem] text-white">
            {t('landing.refferalProgramInfo.partnerProgram')}
          </span>
          {!!userRefInfo.currentReferral && userRefInfo.currentReferral !== ZERO_ADDRESS && (
            <button className="relative custom-transition group bg-white-100 hover:bg-white-200 rounded-[0.4rem] flex items-center justify-center w-[2.4rem] h-[2.4rem]">
              <Link
                to={explorerLinks.accountUrl + userRefInfo.currentReferral}
                target="_blank"
                className="custom-transition opacity-0 group-hover:opacity-100 duration-500 hover:underline absolute top-[-3.5rem] font-500 rounded-[0.6rem] backdrop-blur-[1rem] flex items-center justify-center py-[0.8rem] px-[1.6rem] w-auto whitespace-nowrap text-white bg-white-100"
              >
                Inviter: {shortenAddress(userRefInfo.currentReferral)}
              </Link>
              <img className="custom-transition opacity-50 group-hover:opacity-100" src={CircleInfoIcon} alt="" />
            </button>
          )}
        </div>
        <span className="text-[1.4rem] text-white-500 leading-[1.96rem] font-500">
          {type === TYPES.TIERS ? (
            <Trans
              i18nKey="landing.refferalProgramInfo.tiersPartnersBonus"
              defaults="Your partners will get bonuses, and you will receive +{{value}} $POLLY (10% of their slot)."
              values={{
                value: !!currentTier?.index ? TIERS?.[currentTier?.index]?.info?.refBonus : TIERS?.[0]?.info?.refBonus,
              }}
            />
          ) : (
            <Trans
              i18nKey="landing.refferalProgramInfo.publicPartnersBonus"
              defaults="Your partners will get bonuses, and you will receive $POLLY (5% of their slot)."
              values={{
                value: !!currentTier?.index ? TIERS?.[currentTier?.index]?.info?.refBonus : TIERS?.[0]?.info?.refBonus,
              }}
            />
          )}
        </span>
      </div>
      <div className="flex space-x-[1rem] items-center w-full">
        <div className="flex items-center justify-center relative h-[4rem] w-full rounded-full border-solid p-[0.1rem] border-white-100 bg-white-50 overflow-hidden">
          <div className="flex items-center justify-between px-[1.6rem] w-full h-full bg-[rgba(18,18,20,0.80)] rounded-full z-[9]">
            {!account && (
              <div
                className={`absolute top-0 left-0 w-full h-full flex items-center justify-start backdrop-blur-md rounded-full bg-white-50 px-[1.6rem] font-600 ${colorStyles.text}`}
              >
                {t('landing.refferalProgramInfo.connectAndShowLink')}
              </div>
            )}
            <span className="text-[1.4rem] text-white font-400 max-w-[80%] overflow-auto whitespace-nowrap truncate">
              {refLink}
            </span>
            <button onClick={() => copy(refLink)}>
              <span
                className={`text-[1.4rem] ${colorStyles.text} font-400 whitespace-nowrap custom-transition hover:text-[rgba(255,198,100,1)]`}
              >
                {t('main.copyLink')}
              </span>
            </button>
          </div>
          <img
            className="absolute top-0 z-[1] w-full h-full ref-shadow-animation object-cover"
            src={`/images/landing/tiersDashboard/${
              type === TYPES.TIERS ? 'orangeRefShadow.webp' : 'purpleRefShadow.webp'
            }`}
          />
        </div>
        {/* <button
          onClick={() => onOpen()}
          className={`flex !text-black items-center justify-center rounded-full ${colorStyles.bg} h-[4rem] w-[4rem] shrink-0`}
        >
          <img className="!text-black" src={ShareIcon} />
        </button> */}
      </div>
      {/* refbar */}
      {renderRefbar}
      {/* refAccept */}
      {userRefInfo.exists !== null && userRefInfo.exists === false && isHaveRef && (
        <div className="z-[10] p-[2rem] absolute left-1/2 -translate-x-1/2 bottom-[2rem] flex flex-col space-y-[1.6rem] w-[calc(100%+4rem)] rounded-[2rem] border border-solid border-white-200 bg-white-800 backdrop-blur-[2rem]">
          <div className="flex flex-col space-y-[0.8rem]">
            <span className="text-black text-[2rem] font-600">
              {t('landing.refferalProgramInfo.activatePartnerProgram')}
            </span>
            <span className="text-black-500 text-[1.4rem] font-500">
              {t('landing.refferalProgramInfo.activatePartnerProgramDesc')}
            </span>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={() => refRegister()}
              disabled={transactionInfo.isWaiting}
              className="flex items-center justify-center flex-1 h-[4rem] bg-black border border-solid border-white-50 rounded-[1.2rem]"
            >
              <span className="text-white text-[1.6rem] font-500 disabled:opacity-80">
                {transactionInfo.isWaiting ? (
                  <div className="flex items-center justify-center loading-animation__white" />
                ) : (
                  t('landing.refferalProgramInfo.activatePartnerProgramBtn')
                )}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
