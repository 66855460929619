import React, { useEffect, useMemo, useState } from 'react';
import { GetMoreBtn } from '../../features/previewPage/GetMoreBtn';
import { ActivateCodeModal } from '../../features/previewPage/ActivateCodeModal';
import { useModal } from '../../helpers/hooks/useModal';
import PromocodeIcon from '../../assets/icons/main/promocode.svg';
import { useWeb3React } from '@web3-react/core';
import { ActivateModal } from '../../components/Modals/ActivateModal';

export const PreviewProjectLayout = ({ children, style }) => {
  const [waitToCode, setWaitToCode] = useState(false);
  const { account } = useWeb3React();
  const { openedModal, onOpen, onClose } = useModal();
  const { openedModal: openedWalletModal, onOpen: onOpenWallet, onClose: onCloseWallet } = useModal();

  useEffect(() => {
    if (waitToCode && account) {
      onOpen();
      setWaitToCode(false);
    }
  }, [waitToCode, account]);

  const openCodeAfterConnect = () => {
    setWaitToCode(true);
    onOpenWallet();
  };

  const renderButton = useMemo(() => {
    return (
      <button
        className="previewPage-header-btn rounded-[1.2rem] border border-solid border-white-200 bg-[#985AFF] hover:bg-[#B678F5] custom-transition flex items-center justify-center space-x-[1rem] py-[0.8rem] px-[1.6rem]"
        onClick={account ? () => onOpen() : () => openCodeAfterConnect()}
      >
        <span className="text-white text-[1.6rem] font-medium">Use code</span>
        <img className="h-[2.4rem] w-[2.4rem]" src={PromocodeIcon} alt="" />
      </button>
    );
  }, [account]);

  return (
    <div
      style={style}
      className="overflow-x-hidden relative min-h-screen w-screen flex flex-col items-center justify-center lg:justify-start lg:mt-[6.2rem] lg:px-[1.6rem]"
    >
      <header className="lg:backdrop-blur-md max-w-[144rem] z-[10] fixed top-0 left-1/2 -translate-x-1/2 w-full lg:bg-[rgba(2,2,4,0.80)] lg:max-w-full lg:py-[1rem] lg:px-[1.6rem] py-[3rem] pl-[6rem] pr-[4rem] flex items-center justify-between">
        <img className="h-[4rem] lg:h-[3rem]" src="/logo.svg" alt="" />
        <div className="flex items-center justify-end space-x-[1.2rem]">
          {renderButton}
          <GetMoreBtn className={'lg:hidden'} />
        </div>
      </header>
      {children}
      <img
        className="absolute top-0 left-0 w-full h-auto lg:w-[100rem] lg:left-[-5rem] lg:h-[50rem] z-[-1] lg:hidden"
        src="/images/layouts/default/topLight-purple.webp"
        alt=""
      />
      <ActivateCodeModal openedModal={openedModal} handleCloseModal={onClose} />
      <ActivateModal openedModal={openedWalletModal} handleCloseModal={onCloseWallet} />
    </div>
  );
};
