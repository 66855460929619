import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { ActivateModal } from '../../../components/Modals/ActivateModal';
import { ActivateNftModal } from '../ActivateNftModal';
import { useModal } from 'helpers/hooks/useModal';

export const NftBanner = ({ className = '' }) => {
  const { openedModal, onOpen, onClose } = useModal();
  const { openedModal: openedWalletModal, onOpen: onOpenWalletModal, onClose: onCloseWalletModal } = useModal();

  const { account, chainId } = useWeb3React();

  const onClick = () => {
    if (account) {
      onOpen();
    } else {
      onOpenWalletModal();
    }
  };

  return (
    <div
      className={`px-[5rem] lg:px-[1.6rem] w-full h-auto flex items-center justify-center mb-[23rem] lg:mb-[4rem] lg:mt-[4rem] ${className}`}
    >
      <div className="lg:overflow-hidden  relative flex h-[30rem] lg:h-[68.9rem] w-full max-w-[140rem] lg:max-w-[35.8rem] border border-solid border-[rgba(255,255,255,0.2)] bg-no-repeat bg-[linear-gradient(270deg,#FFCE1B_0%,#FFB31B_12%,rgba(223,127,24,0.88)_23%,rgba(2,2,4,0.00)_100%)] lg:bg-[linear-gradient(0deg,#FFCE1B_0%,#FFB31B_12%,rgba(223,127,24,0.88)_23%,rgba(2,2,4,0.00)_100%)] rounded-[3rem]">
        <div className="relative overflow-hidden flex items-center justify-between h-full w-full lg:items-start">
          <div className="pl-[4rem] lg:pl-0 lg:pt-[4rem] flex flex-col items-start justify-center max-w-[40rem] w-full lg:items-center lg:max-w-full h-auto space-y-[4.8rem]">
            <div className="flex flex-col items-start justify-start space-y-[1.2rem] lg:justify-center lg:items-center lg:text-center">
              <span className="text-white text-[4rem] font-600 lg:text-[3.2rem]">Activate Your Pass</span>
              <span className="text-white-500 text-[1.6rem] font-500 leading-[140%] lg:text-[1.4rem]">
                Activate your Polynetica NFT Entry Pass for <br /> Premium Access and Priority Features.
              </span>
            </div>

            <button
              onClick={() => onClick()}
              className="shadow-previewNftButton bg-[#985AFF] px-[1.6rem] h-[4.8rem] rounded-[1.2rem] border border-solid border-[rgba(255,255,255,0.2)] hover:opacity-80 z-[3]"
            >
              <span className="text-white font-500 text-[1.6rem]">
                {account ? 'Check your Access' : 'Connect your Wallet now'}
              </span>
            </button>
          </div>
          <img
            src="/previewPage/nft-stars-bg.webp"
            className="animate-pulse w-[56.6rem] h-[56.6rem] absolute top-1/2 left-0 -translate-y-1/2 lg:left-1/2 lg:-translate-x-1/2"
            alt="nft-stars-bg-big"
          />
          <img
            src="/previewPage/nft-stars-bg.webp"
            className="animate-pulse w-[46.1rem] h-[46.1rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            alt="nft-stars-bg"
          />
        </div>
        <div className="">
          <img
            src="/previewPage/nft-card.webp"
            className="rounded-[1.5rem] shadow-previewNftCard object-fit h-[30rem] rotate-[15deg] absolute top-[-3.5rem] left-[60%] z-[2] lg:left-[61%] lg:-translate-x-1/2 lg:top-auto lg:bottom-[9rem]"
            alt=""
          />
          <img
            src="/previewPage/nft-card.webp"
            className="rounded-[1.5rem] shadow-previewNftCard h-[30rem] rotate-[-15deg] absolute top-[3rem] left-[55%] lg:left-[40%] lg:-translate-x-1/2 lg:top-auto lg:bottom-[3.5rem]"
            alt=""
          />
        </div>
      </div>
      <ActivateModal openedModal={openedWalletModal} handleCloseModal={onCloseWalletModal} />
      <ActivateNftModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
