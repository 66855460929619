import React, { useCallback } from 'react';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';

export const SecondEmptyTimer = ({ time, onComplete, isCompleted }) => {
  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return <span className=""> {seconds}s</span>;
    }
  }, []);

  return <Countdown renderer={renderer} autoStart date={fromUnixTime(time)} onComplete={onComplete} overtime={false} />;
};
