import { createSlice } from '@reduxjs/toolkit';

export const saleRefferalSlice = createSlice({
  name: 'saleRefferal',
  initialState: {
    info: {
      pol: 0,
      polly: 0,
    },
  },
  reducers: {
    updateBalance(state, action) {
      state.saleRefferal.balance = { ...state.saleRefferal.balance, ...action.payload };
    },
  },
});

export const saleRefferalReducer = saleRefferalSlice.reducer;

export const { updateBalance } = saleRefferalSlice.actions;
