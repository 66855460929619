import React, { useEffect } from 'react';
import { LeftBar } from './LeftBar';
import { RightBar } from './Rightbar';
import { useCheckTiersReflink } from '../../../../helpers/hooks/useCheckTiersReflink';

export const Tiers = ({ isStartTimerEnd, setStartTimerEnd }) => {
  const { uplineData, checkReflink } = useCheckTiersReflink();

  useEffect(() => {
    checkReflink();
  }, []);

  return (
    <div className="project-steps-animation flex items-start overflow-hidden justify-between space-x-[5.3rem] h-[74rem] w-full space-x-[1rem] p-[4rem] bg-[#0C0C0E] rounded-[3rem] border-solid border-[0.1rem] border-white-100 mt-[2rem] lg:flex-col lg:space-x-0 lg:p-[2rem] lg:h-full lg:border-none lg:space-y-[2rem]">
      <LeftBar
        isStartTimerEnd={isStartTimerEnd}
        setStartTimerEnd={setStartTimerEnd}
        uplineAddress={uplineData?.value}
      />
      <RightBar isStartTimerEnd={isStartTimerEnd} uplineAddress={uplineData?.value} />
    </div>
  );
};
