import React, { useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Modal } from '../../../components/Modal';
import { useCodeNft } from '../../../helpers/hooks/useCodeNft';
import { Input } from '../../../components';

const STATUSES = {
  INIT: 'init',
  ERROR: 'error',
  CLAIMING: 'claiming',
  READY_CLAIM: 'ready claim',
  RECEIVED: 'received',
  ALREADY_HAVE: 'already have',
  LOADING: 'loading',
};

export const ActivateCodeModal = ({ openedModal, handleCloseModal }) => {
  const [status, setStatus] = useState(STATUSES.INIT);
  const [enteredPromo, setEnteredPromo] = useState(false);
  const [promocode, setPromocode] = useState('');
  const { account } = useWeb3React();

  const {
    checkReceived,
    getTotalDistribution,
    claimNft,
    resetClaimInfo,
    resetTotalDistribution,
    totalDistribution,
    claimInfo,
  } = useCodeNft();

  useEffect(() => {
    if (openedModal && account && enteredPromo && promocode) {
      getTotalDistribution(promocode);
    }
  }, [openedModal, account, enteredPromo, promocode]);

  useEffect(() => {
    if (enteredPromo) {
      if (claimInfo.isLoading) {
        setStatus(STATUSES.LOADING);
      } else {
        if (claimInfo.isClaimed) {
          setStatus(STATUSES.ALREADY_HAVE);
        } else if (claimInfo.isClaiming) {
          setStatus(STATUSES.CLAIMING);
        } else if (claimInfo.isClaimedNow) {
          setStatus(STATUSES.RECEIVED);
        } else if (claimInfo.isError) {
          setStatus(STATUSES.ERROR);
        } else {
          setStatus(STATUSES.READY_CLAIM);
        }
      }
    }
  }, [enteredPromo, claimInfo]);

  const modalClose = () => {
    setEnteredPromo(false);
    setPromocode('');
    handleCloseModal();
    resetClaimInfo();
    resetTotalDistribution();
    setStatus(STATUSES.INIT);
  };

  const applyPromo = () => {
    setEnteredPromo(true);
  };

  useEffect(() => {
    if (totalDistribution.data.total > totalDistribution.data.distributed) {
      const timer = setTimeout(() => {
        checkReceived(account, promocode);
      }, 2500);
      return () => clearTimeout(timer);
    }
    if (totalDistribution.isError) {
      setStatus(STATUSES.ERROR);
    }
  }, [totalDistribution, account, promocode]);

  const tryAgain = () => {
    setEnteredPromo(false);
    setPromocode('');
    resetClaimInfo();
    resetTotalDistribution();
    setStatus(STATUSES.INIT);
  };

  const currentState = useMemo(() => {
    switch (status) {
      case STATUSES.INIT:
        return {
          title: 'Get Your NFT Pass',
          desc: 'You can use promo code and get nft',
          img: '/previewPage/codeModal/init-code.webp',
          btnText: 'Submit',
          btnFunc: () => applyPromo(),
        };
      case STATUSES.READY_CLAIM:
        return {
          title: 'NFT is Available for Claim',
          desc: 'Congrats! You received an NFT pass. Collect it to access Polynetica.',
          img: '/previewPage/nftModal/card.webp',
          btnText: 'Collect',
          btnFunc: () => claimNft(promocode),
        };
      case STATUSES.ERROR:
        return {
          title: 'Promocode Does Not Exist',
          desc: 'Oops, looks like this promocode does not exist. Go back and try again!',
          img: '/previewPage/codeModal/error-code.webp',
          btnText: 'Back',
          btnFunc: () => tryAgain(),
        };
      case STATUSES.RECEIVED:
        return {
          title: 'NFT Claim Success!',
          desc: 'Now you can participate in Polynetica.',
          img: '/previewPage/nftModal/card.webp',
          btnText: 'Close',
          btnFunc: () => modalClose(),
        };
      case STATUSES.ALREADY_HAVE:
        return {
          title: 'You Already Have an NFT Pass',
          desc: 'Looks like you already have an NFT Pass.',
          img: '/previewPage/nftModal/card.webp',
          btnText: 'Back',
          btnFunc: () => modalClose(),
        };
      case STATUSES.CLAIMING:
        return {
          title: 'Claiming NFT pass',
          desc: '',
          img: '/previewPage/nftModal/loader.svg',
          btnText: 'Claiming...',
          btnFunc: () => {},
        };
      default:
        return {
          title: 'Transaction in Progress',
          desc: '',
          img: '/previewPage/nftModal/loader.svg',
          btnText: 'Please wait',
          btnFunc: () => {},
        };
    }
  }, [status]);

  return (
    <Modal isOpened={openedModal} onClose={modalClose}>
      <div className="flex flex-col items-center justify-center lg:items-start bg-white-50 backdrop-blur-[5rem] border border-solid border-white-200 min-h-[53.8rem] w-[46rem] rounded-[3rem] p-[4rem] pt-[8rem] lg:px-[2rem] lg:pb-[8rem] lg:rounded-none lg:rounded-t-[3rem] lg:w-full lg:min-h-[50vh] mt-auto">
        <div className="h-[20rem] lg:h-[14rem] w-full flex items-center justify-center mb-[4rem]">
          <img
            className={`${
              status === STATUSES.LOADING || status === STATUSES.CLAIMING ? 'h-[6rem] animate-spin' : 'h-full'
            } ${status === STATUSES.READY_CLAIM ? 'shadow-previewNftYellow rounded-[2rem]' : ''} w-auto`}
            src={currentState.img}
            alt=""
          />
        </div>
        <div className="flex flex-col items-center justify-center text-center w-full">
          <div className="flex flex-col item-center justify-center w-full mb-[4rem] space-y-[1.2rem]">
            <span className="text-[3rem] font-600 text-white">{currentState.title}</span>
            <span className="text-[1.4rem] font-400 text-white-500">{currentState.desc}</span>
          </div>

          {status === STATUSES.INIT && (
            <Input
              type="text"
              className="mb-[2rem]"
              placeholder="Enter your promocode here"
              onChange={(e) => setPromocode(e.target.value)}
            />
          )}

          <button
            onClick={() => currentState.btnFunc()}
            disabled={
              !promocode.length ||
              status === STATUSES.ALREADY_HAVE ||
              status === STATUSES.LOADING ||
              status === STATUSES.CLAIMING
            }
            className="shadow-previewNftButton bg-[#985AFF] px-[1.6rem] h-[4.8rem] w-full rounded-[1.2rem] border border-solid border-[rgba(255,255,255,0.2)] hover:opacity-80 z-[3] disabled:bg-white-50 disabled:shadow-none disabled:border-white-50"
          >
            <span className="text-white font-500 text-[1.6rem]">{currentState.btnText}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};
