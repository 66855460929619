import React from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { UAParser } from 'ua-parser-js';

import config from '../helpers/config';
import { WalletConnect } from '../libs/wc2';

import { Trans } from 'react-i18next';

export const supportedChainIds = [137, 80002];

export const RPC = {
  137: 'https://rpc-mainnet.matic.quiknode.pro',
  80002: 'https://rpc.ankr.com/polygon_amoy',
};

export const [metaMask, metaMaskHooks] = initializeConnector((actions) => new MetaMask({ actions }));

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: '3837013593c5fd468ae04877b786f96c',
        chains: [config.allowedChainId],
        optionalChains: [config.allowedChainId],
        showQrModal: true,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '9999999',
          },
        },
      },
    }),
);

export const injectedConnector = new InjectedConnector({
  supportedChainIds,
});

const UA = typeof window !== 'undefined' ? new UAParser(navigator.userAgent) : new UAParser('');

export const WALLETS = [
  {
    title: 'Trust Wallet',
    subtitle: (<Trans i18nKey="connectWalletModal.dappConnect">DApp in app</Trans>),
    connector: metaMask,
    icon: '/icons/wallets/TW.webp',
    disabled:
      typeof window !== 'undefined' || typeof window?.trustwallet !== 'undefined'
        ? !window?.trustwallet?.ethereum
        : false,
  },
  {
    title: 'TokenPocket',
    subtitle: (<Trans i18nKey="connectWalletModal.dappConnect">DApp in app</Trans>),
    connector: metaMask,
    icon: '/icons/wallets/TP.webp',
    disabled:
      typeof window !== 'undefined' ? !window?.ethereum?.isTokenPocket || !window?.ethereum?.isTokenPocket : false,
  },
  {
    title: 'MetaMask',
    subtitle: (<Trans i18nKey="connectWalletModal.desktopDappConnect">Desktop / DApp in app</Trans>),
    connector: metaMask,
    icon: '/icons/wallets/MM.webp',
    disabled: typeof window !== 'undefined' ? !window?.ethereum?.isMetaMask : false,
  },
  {
    title: 'WalletConnect',
    subtitle: (<Trans i18nKey="connectWalletModal.allConnect">Any wallet and browser</Trans>),
    connector: walletConnectV2,
    icon: '/icons/wallets/WC.webp',
    disabled:
      (UA.getBrowser().name === 'Chrome WebView' || UA.getBrowser().name === 'Chrome') &&
      parseInt(UA?.getBrowser?.()?.major) <= 87,
  },
];
