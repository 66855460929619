import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import clsx from 'clsx';
import CloseIcon from '../../assets/icons/main/close.svg';

export const Modal = ({ isOpened, onClose, children, className, isDisableOnClose, withoutClose, classModal }) => {
  const preventDefaultCallBack = (callback) => (e) => {
    e.preventDefault();

    callback && callback(e);
  };

  return (
    <DialogOverlay
      className={`header-dialog-overlay !backdrop-blur-[15px] lg:flex lg:flex-col lg:justify-end  ${classModal}`}
      isOpen={isOpened}
      as="div"
      onClick={!isDisableOnClose && preventDefaultCallBack(onClose)}
    >
      <DialogContent
        className={clsx(
          className,
          'flex self-center justify-center lg:m-0 lg:w-screen lg:max-w-full relative lg:self-start lg:-mb-5 outline-none',
        )}
        as="div"
        aria-label="modal"
      >
        {children}

        {!isDisableOnClose && (
          <div
            className={`${
              withoutClose && 'hidden'
            } flex right-[2rem] top-[2rem] cursor-pointer w-[2.4rem] h-[2.4rem] absolute hover:opacity-80`}
            onClick={preventDefaultCallBack(onClose)}
            onTouchEnd={preventDefaultCallBack(onClose)}
          >
            <img className="h-[2.4rem] w-[2.4rem]" src={CloseIcon} />
          </div>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};
