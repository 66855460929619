import React, { useState } from 'react';
import { ALL_TIERS_STATUS } from '../../../helpers/tiers';
import { PROJECT_STATUS } from '../../../helpers/projectProgress';
import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../store/tierSlice/selectors';

export const RoadMap = ({ currentProjectStatus, className }) => {

  const { allStatus } = useSelector(getTiersInfo);

  const roadMapList = [
    {
      title: '$POLLY Tiers',
      isActive: currentProjectStatus === PROJECT_STATUS.TIERS,
      isEnded: allStatus === ALL_TIERS_STATUS.ENDED
    },
    {
      title: '$POLLY Public Sale',
      isActive: currentProjectStatus === PROJECT_STATUS.PUBLIC_SALE,
      isEnded: false,
    },
    {
      title: 'Marketing Programs',
      isActive: currentProjectStatus === PROJECT_STATUS.MATRIX,
      isEnded: false,
    },
  ];

  const renderIcon = (num, isActive, isEnded) => {
    if (isActive) {
      return (
        <div className="flex items-center justify-center w-[2.4rem] h-[2.4rem]">
          <div className="h-[1rem] w-[1rem] bg-[#CEFA2F] rounded-full animate-ping" />
        </div>
      )
    }
    if (isEnded) {
      return (
        <div className="flex items-center justify-center w-[2.4rem] h-[2.4rem] rounded-[0.6rem] bg-[#CEFA2F]">
          <span className="text-[1.4rem] text-[#020204] font-500 leading-[1.96rem]">{num}</span>
        </div>
      )
    }
    return (
      <div className="flex items-center justify-center w-[2.4rem] h-[2.4rem] rounded-[0.6rem] border-dashed border-[0.1rem]">
        <span className="text-[1.4rem] text-white font-500 leading-[1.96rem]">{num}</span>
      </div>
    )
  }

  return (
    <div
      className={`flex-1 lg:flex-none flex items-center justify-center space-x-[1.2rem] lg:overflow-auto lg:w-full lg:shrink-0 lg:justify-start lg:items-start ${className}`}
    >
      {roadMapList.map((item, itemIndex) => {
        return (
          <div className="flex items-center space-x-[1.2rem]" key={itemIndex}>
            <div className={`flex items-center space-x-[1.2rem] opacity-30 ${(item.isActive || item.isEnded) && '!opacity-100'}`} key={itemIndex}>
              {renderIcon(itemIndex+1, item.isActive, item.isEnded)}
              <span className={`text-[1.4rem] font-500 text-white whitespace-nowrap`}>{item?.title}</span>
            </div>
            <div
              className={`w-[3.75rem] border-solid border-white border-[0.05rem] opacity-20 lg:w-[2rem]  ${
                itemIndex === 2 ? 'hidden' : ''
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};
