import React, { useEffect, useState } from 'react';
import arrowIcon from '../../../assets/icons/landing/slider/arrowIcon.svg';

export const Slider = ({ slides, children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    setProgress(0);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    setProgress(0);
  };

  useEffect(() => {
    const slideDuration = 5000;
    const intervalTime = 10;

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + (100 * intervalTime) / slideDuration;
      });
    }, intervalTime);

    const slideTimeout = setTimeout(() => {
      nextSlide();
    }, slideDuration);

    return () => {
      clearInterval(progressInterval);
      clearTimeout(slideTimeout);
    };
  }, [currentIndex]);

  return (
    <>
      <div className="flex relative w-full h-[50rem] overflow-hidden lg:h-[60rem] items-center lg:max-w-[200rem] lg:items-center lg:justify-center">
        <div
          className="flex items-center w-full slider-animation lg:w-full lg:relative"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {children}
        </div>

        <div className="absolute top-0 w-[90%] h-[2px] left-1/2 -translate-x-1/2 bg-white-100 rounded-[1rem] lg:w-[85%]">
          <div className="h-full bg-white rounded-[1rem]" style={{ width: `${progress}%` }}></div>
        </div>
        <button
          onClick={prevSlide}
          className="arrowButton absolute flex items-center justify-center left-0 h-full w-[25rem] lg:w-[10rem]"
        >
          <img className="left-[5.05rem] absolute lg:left-[0.8rem]" src={arrowIcon} />
          <img className="absolute opacity-0 shadow h-full top-0" src="/images/landing/slider/leftShadow.webp" />
        </button>
        <button
          onClick={nextSlide}
          className="arrowButton absolute flex items-center justify-center right-0 h-full w-[25rem] lg:w-[10rem]"
        >
          <img className="right-[5.05rem] absolute rotate-180 lg:right-[0.8rem]" src={arrowIcon} />
          <img className="absolute opacity-0 shadow h-full top-0" src="/images/landing/slider/rightShadow.webp" />
        </button>
      </div>
    </>
  );
};
