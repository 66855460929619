import React, { useState } from 'react';

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import arrowIcon from '../../../../assets/icons/landing/programs/faqList/arrowIcon.svg';
import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <AccordionItem className="w-[44.3rem] lg:w-full group ">
      <AccordionItemHeading className="w-full">
        <AccordionItemButton className="flex items-center justify-between">
          <button
            onClick={() => setIsActive(!isActive)}
            className={`custom-transition flex items-center justify-between p-[2rem] bg-white-50 w-[44.3rem] text-white-500 lg:w-full group-hover:bg-white-70 hover:text-white ${
              isActive ? 'rounded-t-[2rem]' : 'rounded-[2rem]'
            } `}
          >
            <span className="text-[2rem] font-600 space-grotesk lg:text-[1.6rem]">{title}</span>
            <img
              alt=""
              className={`w-[24px] h-[24px] lg:w-[20px] lg:h-[20px] custom-transition ease-out ${
                isActive ? 'rotate-180' : 'rotate-0'
              }`}
              src={arrowIcon}
            />
          </button>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={`custom-transition px-6 pb-6 bg-white-50 group-hover:bg-white-70 ${isActive && 'rounded-b-[2rem]'}`}>
        <span className="text-white-500 font-400 text-[1.4rem] leading-[1.97rem]">{content}</span>
      </AccordionItemPanel>
    </AccordionItem>
  );
};
