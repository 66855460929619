import React from "react";
import { Link } from "react-router-dom";
import BookmarkIcon from '../../../assets/icons/main/bookmark.svg';

export const GetMoreBtn = ({ className }) => {
    return (
        <Link 
            to="https://t.me/polynetica"
            target="_blank" 
            className={`flex items-center justify-center bg-white-50 border border-solid border-white-200 space-x-[1rem] py-[0.8rem] px-[1.6rem] rounded-[1.2rem] custom-transition ${className}`}
          >
            <span className="text-white text-[1.6rem] font-medium">Get info</span>
            <img className="h-[2.4rem] w-[2.4rem]" src={BookmarkIcon} alt="" />
          </Link>
    )
}