import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Title } from '../../../components/Landing/Title';
import { Trans } from 'react-i18next';
import { Slider } from '../../../components/Landing/Slider';

export const Partnerships = ({ className }) => {
  const slides = [
    {
      title: (
        <span className="text-[2.4rem] font-500 leading-[3.36rem] text-white max-w-[50.1rem] lg:text-[1.6rem] lg:leading-[2.24rem]">
          <Trans
            i18nKey="landing.partnerships.slide1"
            defaults="Polynetica leverages smart contracts on the Polygon <white500>network to handle user transactions and rewards.</white500>"
            components={{ white500: <span className="text-white-500" /> }}
          />
        </span>
      ),
      img: (
        <img
          className="absolute h-[73rem] right-[-5rem] lg:h-[85%] lg:max-w-[400rem] lg:left-1/2 lg:-translate-x-1/2 lg:bottom-[-5rem]"
          src="/images/landing/partnerships/3.webp"
        />
      ),
    },
    {
      title: (
        <span className="text-[2.4rem] font-500 leading-[3.36rem] text-white max-w-[50.1rem] lg:text-[1.6rem] lg:leading-[2.24rem]">
          <Trans
            i18nKey="landing.partnerships.slide2"
            defaults="$POLLY holders can amplify their earnings post-sale through marketing programs. <white500> These programs empower the community to become ambassadors, driving adoption and expanding $POLLY's reach.</white500>"
            components={{ white500: <span className="text-white-500" /> }}
          />
        </span>
      ),
      img: (
        <>
          <img
            className="absolute h-full max-w-[48.8rem] right-0 lg:hidden"
            src="/images/landing/partnerships/2.webp"
          />
          <img
            className="hidden lg:flex absolute bottom-0 h-[30rem] bottom-[-3rem] lg:h-[60%] lg:max-w-[110%] lg:left-1/2 lg:-translate-x-1/2 "
            src="/images/landing/partnerships/2mob.webp"
          />
        </>
      ),
    },
    {
      title: (
        <span className="text-[2.4rem] font-500 leading-[3.36rem] text-white max-w-[50.1rem] lg:text-[1.6rem] lg:leading-[2.24rem]">
          <Trans
            i18nKey="landing.partnerships.slide3"
            defaults="The POLLY token ($POLLY) is the community token that powers the protocol <white500>rewarding active members with dividends based on their contributions.</white500>"
            components={{ white500: <span className="text-white-500" /> }}
          />
        </span>
      ),
      img: (
        <>
          <img
            className="absolute bottom-0 h-[48rem] right-[6rem] z-[11] lg:h-[37.7rem] lg:left-1/2 lg:-translate-x-1/2 "
            src="/images/landing/partnerships/poly/poly.webp"
          />
          <img
            className="absolute w-[15.8rem] right-[42rem] bottom-[3.5rem] z-[1] right-[33rem] lg:w-[12rem] lg:left-[-7.5rem] lg:bottom-[2.8rem]"
            src="/images/landing/partnerships/poly/coin1.webp"
          />
          <img
            className="absolute right-[0rem] w-[15.2rem] top-[14rem] z-[1] lg:top-auto lg:bottom-[13.2rem] lg:right-[-1.5rem] lg:z-[1]"
            src="/images/landing/partnerships/poly/coin2.webp"
          />
          <img
            className="absolute w-[9.2rem] right-[31.8rem] top-[7rem] z-[1] lg:top-auto lg:bottom-[22.7rem] lg:left-[5rem]"
            src="/images/landing/partnerships/poly/coin3.webp"
          />
          <img
            className="absolute right-0 bottom-0 z-[0] lg:hidden"
            src="/images/landing/partnerships/poly/shadow.webp"
          />
          <img
            className="hidden lg:flex absolute right-0 bottom-0 z-[0] h-[50rem] w-full"
            src="/images/landing/partnerships/poly/mobShadow.webp"
          />
          <img
            className="absolute right-0 bottom-0 z-[0] h-[33rem] lg:h-[25rem] lg:right-[-2rem]"
            src="/images/landing/partnerships/poly/blocks1.webp"
          />
          <img
            className="absolute right-[27rem] top-0 z-[0] h-[33rem] lg:h-[25rem] lg:left-[-17rem] lg:top-auto lg:bottom-[13rem]"
            src="/images/landing/partnerships/poly/blocks2.webp"
          />
        </>
      ),
    },
  ];

  return (
    <div
      className={`flex flex-col items-center space-y-[8rem]  pt-[4rem] lg:pt-[2rem]  lg:space-y-[4rem] lg:items-center lg:justify-center ${className}`}
    >
      <Title
        className="partnersships-gradient text-[6rem] leading-[6.6rem]"
        title={<Trans i18nKey="landing.partnerships.title">Polynetica Rewards Collaboration</Trans>}
        desc={<Trans i18nKey="landing.partnerships.desc">Building Business Partnerships on the Blockchain</Trans>}
      />
      <Slider slides={slides}>
        {slides.map((item, itemIndex) => (
          <div
            className="flex items-center min-w-full justify-start relative w-full overflow-hidden h-[50rem] px-[8rem] rounded-[3rem] border border-solid border-white-100 shadow-[0px_0px_35px_0px_rgba(255,255,255,0.10)_inset] lg:shadow-none lg:h-[60rem] lg:px-[2rem] lg:items-start lg:py-[4rem]"
            key={itemIndex}
          >
            {item?.title}
            {item?.img}
          </div>
        ))}
      </Slider>
    </div>
  );
};
