import { BigNumber } from '@ethersproject/bignumber';
import config from './config';

export const MAX_VALUE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const DEFAULT_GAS_LIMIT = BigNumber.from(2000000);

export const ContractNames = {
  MATRIX_B: 'matrixBase',
  NFT_PASS: 'nftPass',
  NFT_PROMO: 'nftPromo',
  TIERS_SALE: 'tiersSale',
  PANCAKE: 'pancake',
  POLLY_TOKEN: 'pollyToken',
};

export const CONTRACT_NAMES = {
  MATRIX_B: 'matrixBase',
  NFT_PASS: 'nftPass',
  NFT_PROMO: 'nftPromo',
  TIERS_SALE: 'tiersSale',
  PANCAKE: 'pancake',
  POLLY_TOKEN: 'pollyToken',
};

export const PROGRAM_NAMES = {
  X2: 'x2',
  X4: 'x4',
};

export const nameToType = {
  [PROGRAM_NAMES.X2]: CONTRACT_NAMES.MATRIX_B,
  [PROGRAM_NAMES.X4]: CONTRACT_NAMES.MATRIX_B,
};

export const PROGRAM_MAX_LEVELS = {
  [PROGRAM_NAMES.X2]: 12,
  [PROGRAM_NAMES.X4]: 12,
};

export const PROGRAM_PRICES = {
  [PROGRAM_NAMES.X2]: [0.005, 0.01, 0.02, 0.04, 0.08, 0.16, 0.32, 0.64, 1.25, 2.5, 5, 9.9],
  [PROGRAM_NAMES.X4]: [0.005, 0.01, 0.02, 0.04, 0.08, 0.16, 0.32, 0.64, 1.25, 2.5, 5, 9.9],
};

export const REGISTER_PRICE = PROGRAM_PRICES[PROGRAM_NAMES.X2][0] + PROGRAM_PRICES[PROGRAM_NAMES.X4][0];

export const PROGRAM_CURRENCY = {
  [PROGRAM_NAMES.MATRIX_B]: 'POL',
};

export const DEFAULT_SLIPPAGE_SWAP = 0.5;
export const BUY_TIMEOUT = 10000;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const PUBLIC_URLS = {
  137: 'https://polygon-bor-rpc.publicnode.com',
  97: 'https://bsc-testnet-rpc.publicnode.com',
  80002: 'https://polygon-amoy-bor-rpc.publicnode.com',
}

export const explorerLinks =
  config.allowedChainId === 137
    ? {
        accountUrl: 'https://polygonscan.com/address/',
        txUrl: 'https://polygonscan.com/tx/',
      }
    : {
        accountUrl: 'https://amoy.polygonscan.com/address/',
        txUrl: 'https://amoy.polygonscan.com/tx/',
      };

export const ADD_CHAIN_ETHS_PARAMS = {
  97: {
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-2-s3.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    chainId: '0x61',
  },
  137: {
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    chainId: '0x89',
  },
  80002: {
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://api.zan.top/polygon-amoy'],
    blockExplorerUrls: ['https://amoy.polygonscan.com/'],
    chainId: '0x13882',
  },
};

export const EXPLORER_URL = ADD_CHAIN_ETHS_PARAMS?.[config.allowedChainId]?.blockExplorerUrls[0];
