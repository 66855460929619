import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useGetContract } from './useGetContract';
import { ContractNames } from '../constants';
import { useRequest } from './useRequest';
import { TelegramRepository } from '../../connectors/repositories/telegram';
import { useCallTransaction } from './useCallTransaction';

export const useCheckNft = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [nftCount, setNftCount] = useState(null);
  const [isLocked, setIsLocked] = useState(null);

  const {
    transactionInfo: lockTransactionInfo,
    onCallTransaction,
    setTransactionInfo,
    resetTransactionInfo,
  } = useCallTransaction();

  const {
    data: telegramCode,
    isLoading: isLoadingTelegramCode,
    call: getTelegramCode,
    resetData: resetTelegramCode,
  } = useRequest(TelegramRepository.getTelegramSecretCode);

  const checkLocked = async () => {
    try {
      const contract = await getContract(ContractNames.NFT_PASS);
      const result = await contract.lockedBalanceOfBatch(account);
      if (!!parseInt(result.locked[0])) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const lockNft = async () => {
    try {
      const contract = await getContract(ContractNames.NFT_PASS);
      const result = await contract.lock(1, 1);
      if (result) {
        setIsLocked(true);
      }

      onCallTransaction(result);
    } catch (e) {
      console.log(e);
    }
  };

  const checkNft = async () => {
    try {
      const contract = await getContract(ContractNames.NFT_PASS);
      const result = await contract.balanceOf(account, 1);
      if (result) {
        setNftCount(parseInt(result));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resetNft = () => {
    setNftCount(null);
    setIsLocked(null);
  };

  return {
    nftCount,
    isLoadingTelegramCode,
    telegramCode,
    checkNft,
    resetNft,
    getTelegramCode,
    resetTelegramCode,
    lockNft,
    checkLocked,
    isLocked,
    lockTransactionInfo,
  };
};
