import React, { useCallback } from 'react';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';

export const TiersTimer = ({ time, onComplete, isCompleted }) => {
  const timerItem = (num, without = false) => {
    return (
      <>
        <div className={`h-full w-[3rem] flex items-center  ${without ? 'justify-start' : 'justify-end'}`}>
          <span className="">{num}</span>
        </div>
        {!without && <div className="px-[0.4rem] animate-pulse ">:</div>}
      </>
    );
  };

  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div
          className={`flex items-center px-[0.8rem] h-[3rem] rounded-[0.8rem] bg-[rgba(252,183,62,0.10)] text-[1.6rem] font-500 text-[#FCB73E]`}
        >
          {days > 0 && timerItem(`${days}d`, true)}
          {timerItem(`${hours}h`)}
          {timerItem(`${minutes < 10 ? '0' + minutes : minutes}m`)}
          {timerItem(`${seconds < 10 ? '0' + seconds : seconds}s`, true)}
        </div>
      );
    }
  }, []);

  return <Countdown renderer={renderer} autoStart date={fromUnixTime(time)} onComplete={onComplete} overtime />;
};
