import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { metaMask, walletConnectV2 } from '../../connectors/wallets';
import config from '../../helpers/config';
import { InvalidNetworkModal } from '../../components/Modals/InvalidNetworkModal';
import { useModal } from '../../helpers/hooks/useModal';
import { useBalance } from '../../helpers/hooks/useBalance';

export const ManageProvider = ({ children }) => {
  const { isActive, chainId, account, provider } = useWeb3React();
  const [loaded, setLoaded] = useState(false);
  const { onClose, onOpen, openedModal } = useModal();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isActive && chainId !== config.allowedChainId) {
      onOpen();
    } else {
      onClose();
    }
  }, [chainId, isActive]);

  // useEffect(() => {
  //   onSwitchChain();
  // }, [provider, chainId, account]);

  // useEffect(() => {
  //   if (isActive) {
  //     toast.dismiss('unsupportedChainId');
  //   }
  // }, [chainId, isActive]);

  useEffect(() => {
    metaMask.connectEagerly().catch(() => {
      console.debug('Failed to connect eagerly to metamask');
    });
    setLoaded(true);
  }, []);

  useEffect(() => {
    walletConnectV2.connectEagerly().catch((error) => {
      console.debug('Failed to connect eagerly to walletconnect', error);
    });
  }, []);

  const { isFirstLoaded, fetchBalance, setIsFirstLoaded } = useBalance();

  useEffect(() => {
    if (account && isFirstLoaded && chainId === config.allowedChainId) {
      fetchBalance();
      setIsFirstLoaded(false);
    }
  }, [isFirstLoaded, account]);

  useEffect(() => {
    if (account && chainId === config.allowedChainId && !isFirstLoaded) {
      setIsFirstLoaded(true);
      fetchBalance();
      setIsFirstLoaded(false);
    }
  }, [isFirstLoaded, account]);

  useEffect(() => {
    let interval;
    if (account && chainId === config.allowedChainId && !document?.hidden) {
      interval = setInterval(() => {
        fetchBalance();
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [account, chainId, document]);

  if (loaded) {
    return (
      <>
        {children}
        <InvalidNetworkModal openedModal={openedModal} handleCloseModal={onClose} />
      </>
    );
  }
  return null;
};
