import React from 'react';
import { Menu } from '../BurgerMenu/Sections/Menu';
import { LanguageSelector } from '../../../../components';
import { SocialLinksComp } from '../../../../components/SocialsListComp';

export const MenuWidget = ({ isOpened, onNavigate, onClose }) => {
  if (isOpened) {
    return (
      <div className="lg:hidden absolute top-[7.2rem] right-[2rem] rounded-[2rem] w-[40rem] p-[2.4rem] bg-[#161618] shadow-headerWidget menu-widget">
        <Menu onNavigate={onNavigate} onClose={onClose} />
        <div className="pt-[4.8rem] flex flex-col space-y-[2rem]">
          <LanguageSelector />
          <SocialLinksComp />
        </div>
      </div>
    );
  }
  return null;
};
