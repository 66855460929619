import { createSlice } from '@reduxjs/toolkit';
import { ALL_TIERS_STATUS } from '../../helpers/tiers';

export const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {
    info: {
      currentTier: {},
      allStatus: ALL_TIERS_STATUS.SOON,
    },
  },
  reducers: {
    updateTiers(state, action) {
      state.info = { ...state.info, ...action.payload };
    },
  },
});

export const tiersReducer = tiersSlice.reducer;

export const { updateTiers } = tiersSlice.actions;
