import React from 'react';
import { Title } from '../../../components/Landing/Title';
import TeamsIcon from '../../../assets/icons/landing/advantage/teams.svg';
import IndividualsIcon from '../../../assets/icons/landing/advantage/individuals.svg';
import { Trans } from 'react-i18next';
export const Advantage = () => {
  const advantagesList = [
    {
      title: (<Trans i18nKey="landing.advantage.forTeam.mainTitle">For Teams</Trans>),
      desc: (
        <>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forTeam.title1">Streamlined Collaboration</Trans></span>
            <Trans i18nKey="landing.advantage.forTeam.desc1">Polynetica's smart contract technology facilitates seamless partnerships, automating transactions and eliminating friction.</Trans> 
          </span>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forTeam.title2">Increased Reach & Growth</Trans></span>
            <Trans i18nKey="landing.advantage.forTeam.desc2">Our marketing programs and referral system help you expand your network, reach new audiences, and drive growth.</Trans> 
          </span>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forTeam.title3">Enhanced Transparency & Trust</Trans></span>
            <Trans i18nKey="landing.advantage.forTeam.desc3">The decentralized nature of Polynetica builds trust and transparency, creating a reliable and secure platform for business interactions.</Trans> 
          </span>
        </>
      ),
      shadow: 'teamsShadow',
      icon: TeamsIcon,
      border:
        'border-[#AF7FFF] shadow-[0px_0px_130px_-70px_rgba(175,127,255,0.70)_inset,0px_0px_35px_0px_rgba(255,255,255,0.10)_inset] hover:shadow-[0px_0px_130px_-40px_rgba(175,127,255,0.70)_inset,0px_0px_35px_0px_rgba(255,255,255,0.10)_inset]',
    },
    {
      title: (<Trans i18nKey="landing.advantage.forIndividuals.mainTitle">For Individuals</Trans>),
      desc: (
        <>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forIndividuals.title1">Earn Passive Income</Trans></span>
            <Trans i18nKey="landing.advantage.forIndividuals.desc1">Participate in our marketing programs, earn rewards for referrals, and generate passive income through our innovative tokenomics.</Trans> 
          </span>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forIndividuals.title2">Join a Thriving Community</Trans></span>
            <Trans i18nKey="landing.advantage.forIndividuals.desc2">Сonnect with a vibrant community of entrepreneurs and $POLLY presale participants, sharing knowledge and collaborating to achieve success.</Trans> 
          </span>
          <span className="text-[1.4rem] text-white-500 leading-[160%] font-500">
            <span className="text-white mr-[0.4rem]"><Trans i18nKey="landing.advantage.forIndividuals.title3">Empower Yourself with DeFi</Trans></span>
            <Trans i18nKey="landing.advantage.forIndividuals.desc3">Learn about decentralized finance, gain valuable insights, and access a world of opportunities through our platform.</Trans> 
          </span>
        </>
      ),
      shadow: 'individualsShadow',
      icon: IndividualsIcon,
      border:
        'border-[#23A7FF] shadow-[0px_0px_130px_-70px_rgba(35,167,255,0.70)_inset,0px_0px_35px_0px_rgba(255,255,255,0.10)_inset] hover:shadow-[0px_0px_130px_-40px_rgba(35,167,255,0.70)_inset,0px_0px_35px_0px_rgba(255,255,255,0.10)_inset]',
    },
  ];
  return (
    <div className="flex flex-col items-center space-y-[8rem] pt-[23.3rem] w-full lg:space-y-[4rem] lg:pt-[8rem]">
      <Title
        className="text-[6rem] leading-[6.6rem] text-white max-w-[95rem]"
        title={<Trans i18nKey="landing.advantage.title">Polynetica Advantages</Trans>}
        desc={<Trans i18nKey="landing.advantage.desc">Polynetica isn't just another DeFi protocol. It's an ecosystem designed to empower online businesses, unlocking a world of opportunities and rewards.</Trans>}
      ></Title>
      <div className="flex space-x-[2.4rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[2rem]">
        {advantagesList.map((item, itemIndex) => {
          return (
            <div
              className={`relative flex flex-col w-full p-[4rem] py-[4.5rem] bg-[#020204] rounded-[3rem] border border-transparent overflow-hidden lg:px-[2rem] lg:py-[4rem]`}
              key={itemIndex}
            >
              <div className={`cursor-pointer custom-transition z-[2] absolute top-0 left-0 w-full h-full rounded-[3rem] border-solid border-[0.1rem] hover:border-[0.4rem] ${item?.border}`}></div>
              <div className="flex flex-col items-center space-y-[4.8rem]">
                <div className="flex flex-col items-center space-y-[2rem]">
                  <div className=" flex items-center justify-center w-[5.8rem] h-[5.8rem] rounded-[1.6rem] bg-white-50 border-solid border-[0.1rem] border-white-50 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.30)]">
                    <img src={item?.icon} />
                  </div>
                  <span className="text-[4rem] font-600 text-white space-grotesk lg:text-[3.2rem]">{item?.title}</span>
                </div>
                <div className="flex flex-col space-y-[2.4rem] ">{item?.desc}</div>
              </div>
              <img className="absolute top-0 left-0" src={`/images/landing/advantage/${item?.shadow}.webp`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
