import React from 'react';
import { Title } from '../../../components/Landing/Title';
import affiliateIcon from '../../../assets/icons/landing/programs/affilateIcon.svg';
import activationIcon from '../../../assets/icons/landing/programs/activationIcon.svg';
import { FaqList } from './FaqList';
import { Trans } from 'react-i18next';

export const Programs = () => {
  const programsInfo = [
    {
      title: (<Trans i18nKey="landing.programs.partnerLinking.title">Partner Linking</Trans>),
      desc: (<Trans i18nKey="landing.programs.partnerLinking.desc">Participants are automatically linked to the person who invited them after activating the first level in both programs. This link is permanent and determines profit distribution and bonuses.</Trans>),
      img: affiliateIcon,
    },
    {
      title: (<Trans i18nKey="landing.programs.levelActivation.title">Level Activation</Trans>),
      desc: (<Trans i18nKey="landing.programs.levelActivation.desc">When a level is activated, its cost is distributed among upline partners according to their position within the program.</Trans>),
      img: activationIcon,
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center space-y-[8rem] mt-[8.7rem] lg:space-y-[4rem] lg:mt-[8rem]">
      <Title
        className="text-[6rem] leading-[6.6rem] text-white !max-w-[60rem]"
        title={<Trans i18nKey="landing.programs.title">Marketing Programs</Trans>}
        desc={<Trans i18nKey="landing.programs.desc">DUO and QUATTRO are 2 initial programs, each with 12 levels and independent partner structures</Trans>}
      />
      <div className="flex flex-col items-center justify-center">
        <img src="/images/landing/programs/dashboard.webp" />
        <div className="flex justify-between lg:flex-col lg:space-y-[4.8rem]">
          {programsInfo.map((item, itemIndex) => {
            return (
              <div className="flex flex-col space-y-[1.6rem] first:w-[101%]" key={itemIndex}>
                <div className=" flex items-center justify-center w-[5.8rem] h-[5.8rem] rounded-[1.6rem] bg-white-50 border-solid border-[0.1rem] border-white-50">
                  <img src={affiliateIcon} />
                </div>
                <span className="text-[2rem] font-600 text-white leading-[2.2rem] space-grotesk lg:text-[1.8rem] lg:leading-[1.98rem]">
                  {item?.title}
                </span>
                <span className="text-[1.4rem] text-white opacity-50 leading-[1.96rem] max-w-[70.9rem]">
                  {item?.desc}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <FaqList />
    </div>
  );
};
