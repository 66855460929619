import React, { forwardRef, useMemo } from 'react';
import InputMask from 'react-input-mask';

const Input = forwardRef(({ className, value, mask, onChange, onBlur, ...props }, ref) => {
  const typeClassName = useMemo(() => {
    switch (props?.type) {
      case 'text': {
        return `h-[4.8rem] bg-white-50 border-[0.1rem] w-full border-white-50 rounded-[1.2rem] px-[1.6rem] text-[1.4rem] text-white focus:outline-none lg:max-w-full ${
          !props.readOnly && 'focus:border-[#985AFF] focus:bg-[rgba(152, 90, 255, 0.05)]'
        } ${className}`;
      }
      case 'checkbox': {
        return `w-6 h-6 mr-4 ${className} `;
      }
      default: {
        return `h-[4.8rem] bg-white-50 border-[0.1rem] w-full border-white-50 rounded-[1.2rem] px-[1.6rem] text-[1.4rem] text-white focus:outline-none lg:max-w-full ${className}`;
      }
    }
  }, [props?.type, className]);

  const renderInput = () => {
    if (mask) {
      return (
        <InputMask {...{ mask, onChange, onBlur, value }} className={typeClassName} {...props}>
          {(inputProps) => <input ref={ref} {...inputProps} value={value} />}
        </InputMask>
      );
    }

    return <input ref={ref} className={typeClassName} {...{ mask, onChange, onBlur, value }} {...props} />;
  };

  return renderInput();
});

export { Input };
