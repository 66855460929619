import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useCheckTiersReflink } from '../../../../helpers/hooks/useCheckTiersReflink';
import { Swap } from './Swap';
import { SaleTitle } from './SaleTitle';

export const OpenSale = () => {
  const { uplineData, checkReflink } = useCheckTiersReflink();

  useEffect(() => {
    checkReflink();
  }, []);

  return (
    <div className="project-steps-animation landing-swap-gradient relative flex items-center overflow-hidden justify-between space-x-[3rem] h-[74rem] w-full space-x-[1rem] px-[4rem] py-[4rem] pr-[8rem] bg-[#0C0C0E] rounded-[3rem] border-solid border-[0.1rem] border-white-100 mt-[2rem] lg:flex-col lg:space-x-0 lg:px-[2rem] lg:py-[4rem] lg:h-full lg:border-none lg:space-y-[4rem]">
      <SaleTitle uplineAddress={uplineData?.value} />
      <Swap uplineAddress={uplineData?.value} />
    </div>
  );
};
