import React, { useEffect, useState } from 'react';
import { TIERS, TIERS_STATUS } from '../../../../../helpers/tiers';
import { TierItem } from './Item';
import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../../../store/tierSlice/selectors';
import { ALL_TIERS_STATUS } from '../../../../../helpers/tiers';

export const RightBar = ({ uplineAddress = null }) => {
  const [allTiers, setAllTiers] = useState(TIERS);
  const { currentTier, allStatus } = useSelector(getTiersInfo);

  useEffect(() => {
    if (currentTier && allStatus === ALL_TIERS_STATUS.SALE) {
      let temp = TIERS.slice();
      temp.splice(currentTier.index, 1, currentTier);
      temp = temp.map((item) => {
        if (item.index < currentTier.index) {
          return { ...item, status: TIERS_STATUS.SOLDOUT };
        }
        return item;
      });
      setAllTiers(temp);
    }
    if (allStatus === ALL_TIERS_STATUS.ENDED) {
      let temp = TIERS.slice();
      temp = temp.map((item) => {
        return { ...item, status: TIERS_STATUS.SOLDOUT };
      });
      setAllTiers(temp);
    }
  }, [currentTier, allStatus]);

  return (
    <div className="flex flex-col space-y-[2rem] w-full h-full">
      {allTiers.map((item, itemIndex) => {
        if (!currentTier && allStatus === ALL_TIERS_STATUS.SALE) {
          return (
            <div className="flex w-full" key={`loading-${itemIndex}`}>
              <div className="bg-white-50 h-[11.6rem] w-full flex-1 rounded-l-[2rem] rounded-r-[1rem]"></div>
              <div className="bg-white-50 w-[14rem] h-full border border-dashed border-[#0C0C0E] rounded-r-[2rem] rounded-l-[1rem]"></div>
            </div>
          );
        } else {
          return <TierItem item={item} uplineAddress={uplineAddress} itemIndex={itemIndex} />;
        }
      })}
    </div>
  );
};
